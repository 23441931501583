const JsonIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_114_90)">
        <path d="M8 14.25H9C9.26522 14.25 9.51957 14.1446 9.70711 13.9571C9.89464 13.7696 10 13.5152 10 13.25V9.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.6155 14.25H14.3725C14.6715 14.25 14.9583 14.1312 15.1698 13.9197C15.3812 13.7083 15.5 13.4215 15.5 13.1225C15.5 12.8234 15.3812 12.5366 15.1698 12.3252C14.9583 12.1137 14.6715 11.9949 14.3725 11.9949H13.6225C13.3248 11.9949 13.0393 11.8767 12.8288 11.6662C12.6183 11.4557 12.5 11.1702 12.5 10.8725C12.5 10.7251 12.529 10.5791 12.5854 10.4429C12.6418 10.3067 12.7245 10.183 12.8288 10.0788C12.933 9.97453 13.0567 9.89185 13.1929 9.83544C13.3291 9.77903 13.4751 9.75 13.6225 9.75H15.1667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.4615 3H8.53846C5.47965 3 3 5.47965 3 8.53846V15.4615C3 18.5203 5.47965 21 8.53846 21H15.4615C18.5203 21 21 18.5203 21 15.4615V8.53846C21 5.47965 18.5203 3 15.4615 3Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_114_90">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JsonIcon;
