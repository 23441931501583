"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenInputFocus() {
    const [isTokenInputFocused, setIsTokenInputFocused] = (0, react_1.useState)(false);
    const handleTokenInputFocus = (0, react_1.useCallback)(() => {
        setIsTokenInputFocused(true);
    }, []);
    const handleTokenInputBlur = (0, react_1.useCallback)(() => {
        setIsTokenInputFocused(false);
    }, []);
    return {
        isTokenInputFocused,
        handleTokenInputFocus,
        handleTokenInputBlur,
    };
}
exports.default = useTokenInputFocus;
