import { takeLatest, all, fork } from 'redux-saga/effects'
import AuthAPI from '../Services/AuthApi'
import API from '../Services/Api'

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux'

import { AuthTypes } from '../Redux/AuthRedux'

import { MainTypes } from '../Redux/MainRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'

import {
  signIn,
  signUp,
  forgotPassword,
  setPassword,
  getCustomerData,
  logout
} from './AuthSagas'

import {
  getPricing,
  getSearchPreview,
  addPreviewToList,
  renameList,
  removeList,
  initiateCheckout,
  createExportAfterPayment,
  getInvoice
} from './MainSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;
const api = API;
//const usersApi = UsersAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),

    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp, authApi),
    takeLatest(AuthTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, authApi),
    takeLatest(AuthTypes.SET_PASSWORD_REQUEST, setPassword, authApi),
    takeLatest(AuthTypes.CUSTOMER_DATA_REQUEST, getCustomerData, authApi),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),

    takeLatest(MainTypes.PRICING_REQUEST, getPricing, api),
    takeLatest(MainTypes.SEARCH_REQUEST, getSearchPreview, api),
    takeLatest(MainTypes.LIST_ADD_REQUEST, addPreviewToList, api),
    takeLatest(MainTypes.LIST_EDIT_REQUEST, renameList, api),
    takeLatest(MainTypes.LIST_DELETE_REQUEST, removeList, api),
    takeLatest(MainTypes.CHECKOUT_REQUEST, initiateCheckout, api),
    takeLatest(MainTypes.EXPORT_REQUEST, createExportAfterPayment, api),
    takeLatest(MainTypes.GET_INVOICE_REQUEST, getInvoice, api),
  ])
}
