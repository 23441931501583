import React from 'react'
import { Form, Input } from "antd"

const SetPasswordForm = props => {

  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { ...values }
    delete data.confirm;
    props.onSubmit(data);
  };

  const compareToFirstPassword = (rule, value) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject("Passwords don't match");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Form id="set-password-form" className="custom-modal-form" form={form} onFinish={onFinish}>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "This field is required" },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          maxLength={50}
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item
        dependencies={['password']}
        name="confirm"
        rules={[
          { required: true, message: 'Field is required' },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { validator: compareToFirstPassword }
        ]}
      >
        <Input.Password
          placeholder="Confirm password"
          maxLength={50}
          autoComplete="new-password"
        />
      </Form.Item>
    </Form>
  );
};

export default SetPasswordForm;
