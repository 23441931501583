import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['query'],
  signInSuccess: ['userInfo'],
  signInFailure: ['error'],

  signUpRequest: ['query'],
  signUpSuccess: ['userInfo'],

  forgotPasswordRequest: ['query'],
  forgotPasswordSuccess: ['forgotPasswordResult'],

  setPasswordRequest: ['query'],
  setPasswordSuccess: ['setPasswordResult'],

  customerDataRequest: null,
  customerDataSuccess: ['userInfo'],

  setModalType: ['modalType'],
  setRedirect: ['redirectPath'],

  authErrorReset: null,

  logoutRequest: null,
  logoutSuccess: null,

  resultsReset: null,
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  error: null,
  fetching: false,

  forgotPasswordResult: null,
  setPasswordResult: null,

  modalType: null,
  redirectPath: null

})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUserInfo: state => state.auth.userInfo,
  getRedirectPath: state => state.auth.redirectPath,
}

/* ------------- Reducers ------------- */

export const signInRequest = (state, { query }) => {
  return state.merge({ fetching: true, error: null })
}

export const signInSuccess = (state, { userInfo }) => {
  return state.merge({ fetching: false, error: null, userInfo })
}

export const signInFailure = (state, { error }) => {
  return state.merge({ error, fetching: false })
}

export const signUpRequest = (state, { query }) => {
  return state.merge({ fetching: true, error: null })
}

export const signUpSuccess = (state, { userInfo }) => {
  return state.merge({ fetching: false, error: null, userInfo })
}

export const forgotPasswordRequest = (state, { query }) => {
  return state.merge({ fetching: true, error: null })
}

export const forgotPasswordSuccess = (state, { forgotPasswordResult }) => {
  return state.merge({ fetching: false, error: null, forgotPasswordResult })
}

export const setPasswordRequest = (state, { query }) => {
  return state.merge({ fetching: true, error: null })
}

export const setPasswordSuccess = (state, { setPasswordResult }) => {
  return state.merge({ fetching: false, error: null, setPasswordResult })
}

export const customerDataRequest = (state) => {
  return state.merge({ fetching: true, error: null })
}

export const customerDataSuccess = (state, { userInfo }) => {
  return state.merge({ fetching: false, error: null, userInfo })
}

export const setModalType = (state, { modalType }) => {
  return state.merge({ modalType })
}

export const setRedirect = (state, { redirectPath }) => {
  return state.merge({ redirectPath })
}

export const authErrorReset = (state) => {
  return state.merge({ error: null, fetching: false })
}

export const logoutRequest = (state) => {
  return state.merge({ fetching: true })
}

export const logoutSuccess = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({ forgotPasswordResult: null, setPasswordResult: null })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,

  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,

  [Types.SET_PASSWORD_REQUEST]: setPasswordRequest,
  [Types.SET_PASSWORD_SUCCESS]: setPasswordSuccess,

  [Types.CUSTOMER_DATA_REQUEST]: customerDataRequest,
  [Types.CUSTOMER_DATA_SUCCESS]: customerDataSuccess,

  [Types.SET_MODAL_TYPE]: setModalType,
  [Types.SET_REDIRECT]: setRedirect,

  [Types.AUTH_ERROR_RESET]: authErrorReset,

  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,

  [Types.RESULTS_RESET]: resultsReset,
})
