const InvoiceIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414Z" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.49999 16.966C8.49999 16.963 8.49799 16.961 8.49499 16.961C8.49199 16.961 8.48999 16.963 8.48999 16.966C8.48999 16.969 8.49199 16.971 8.49499 16.971C8.49799 16.971 8.49999 16.969 8.49999 16.966Z" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 10.995H16" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13.995H16" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 17C15.5 16.997 15.498 16.995 15.495 16.995C15.492 16.995 15.49 16.997 15.49 17C15.49 17.003 15.492 17.005 15.495 17.005C15.498 17.005 15.5 17.003 15.5 17Z" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.005 16.99C12.005 16.987 12.003 16.985 12 16.985C11.997 16.985 11.995 16.987 11.995 16.99C11.995 16.993 11.997 16.995 12 16.995C12.003 16.995 12.005 16.993 12.005 16.99Z" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default InvoiceIcon;
