import React from 'react'
import { Form, Input } from "antd"

const ForgotPasswordForm = props => {

  const onFinish = values => {
    const data = { ...values }
    props.onSubmit(data);
  };

  return (
    <Form id="forgot-password-form" className="custom-modal-form" onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "This field is required" },
          { type: 'email', message: 'Invalid email format' }
        ]}
      >
        <Input
          placeholder="Email"
          maxLength={50}
        />
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
