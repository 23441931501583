import React from 'react'
import { Link, NavLink, useLocation } from "react-router-dom"

import './style.less'

const NavigationMenu = props => {
  const { user, setType, setRedirect } = props;

  const location = useLocation();
  const { pathname } = location;

  const options = [
    'search',
    'exports',
    'faq',
    //'blogs'
  ];

  const links = options.map((link, i) => {
    const activeClassName = `header-link active`
    const disabledClassName = `header-link`
    if (link === 'search') {
      return (
        <li key={i}>
          <Link to="/"
            className={pathname === '/' ? activeClassName : disabledClassName}
          >
            <span className="header-link-text">{link}</span>
          </Link>
        </li >
      )
    } else if (link === 'exports') {
      return (
        <li key={i}>
          {
            user ?
              <NavLink to={`/${link}`}
                className={({ isActive }) =>
                  isActive ? activeClassName : disabledClassName
                }
              >
                <span className="header-link-text">{link}</span>
              </NavLink >
              :
              <a className={disabledClassName} onClick={() => {
                setType('login');
                setRedirect('/exports');
              }}>
                <span className="header-link-text">{link}</span>
              </a>

          }
        </li >
      )
    } else {
      return (
        <li key={i}>
          <NavLink to={`/${link}`}
            className={({ isActive }) =>
              isActive ? activeClassName : disabledClassName
            }
          >
            <span className="header-link-text">{link}</span>
          </NavLink >
        </li>
      )
    }
  }
  )

  return (
    <nav className="header-navigation">
      <ul className="header-navigation-list">
        {links}
      </ul >
    </nav >
  );
};

export default NavigationMenu
