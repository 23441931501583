const ExitIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 12H21" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 14L21 12L19 10" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 17V19C20 20.105 19.105 21 18 21H6C4.895 21 4 20.105 4 19V5C4 3.895 4.895 3 6 3H18C19.105 3 20 3.895 20 5V7" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 11V13" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 18.997C4 20.484 5.565 21.451 6.894 20.786L10.894 18.786C11.572 18.446 12 17.754 12 16.996V3" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ExitIcon;
