import { put } from 'redux-saga/effects'

export function* handleSagasError(response, errFn, resetFn) {
  if (response && response.data) {
    if (response.status === 500) {
      yield put(errFn("Server response error"))
    } else {
      yield put(errFn(response.data))
    }
  } else {
    yield put(errFn(response?.originalError?.message || "INTERNET DISCONNECTED"))
  }
  yield put(resetFn())
}
