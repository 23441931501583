import { call, put } from 'redux-saga/effects'
import MainActions from '../Redux/MainRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import { handleSagasError } from './handleSagasError'

export function* getPricing(api, {query}) {
  try {
    const response = yield call(api.getPricing, query)
    if (response && response.ok) {
      yield put(MainActions.pricingSuccess(response.data))
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getSearchPreview(api, { query }) {
  try {
    const response = yield call(api.getSearchPreview, query)
    if (response && response.ok) {
      yield put(MainActions.searchSuccess(response.data))
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* addPreviewToList(api, { query }) {
  try {
    const response = yield call(api.addPreviewToList, query)
    if (response && response.ok) {
      yield put(MainActions.listAddSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* renameList(api, { query }) {
  try {
    const response = yield call(api.renameList, query)
    if (response && response.ok) {
      yield put(MainActions.listEditSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* removeList(api, { query }) {
  try {
    const response = yield call(api.removeList, query)
    if (response && response.ok) {
      yield put(MainActions.listDeleteSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* initiateCheckout(api, { query }) {
  try {
    const response = yield call(api.initiateCheckout, query)
    if (response && response.ok) {
      yield put(MainActions.checkoutSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* createExportAfterPayment(api, { query }) {
  try {
    const response = yield call(api.createExportAfterPayment, query)
    if (response && response.ok) {
      yield put(MainActions.exportSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}

export function* getInvoice(api, { query }) {
  try {
    const response = yield call(api.getInvoice, query)
    if (response && response.ok) {
      let link = document.createElement('a')
      link.href = response.data
      link.download = 'invoice'
      link.click()
      yield put(MainActions.getInvoiceSuccess(response.data))
      yield put(MainActions.resultsReset())
    } else {
      yield* handleSagasError(response, ErrorsActions.errorSave, MainActions.fetchingReset)
    }
  } catch (e) {
    yield put(MainActions.fetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: ' + e))
  }
}
