import React, { useState, useEffect } from 'react'
import { Spin, Modal, message } from 'antd'
import { connect } from 'react-redux'

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import LoginForm from '../../Components/Forms/LoginForm'
import SignupForm from '../../Components/Forms/SignupForm'
import ForgotPasswordForm from '../../Components/Forms/ForgotPasswordForm'
import AuthActions from '../../Redux/AuthRedux'
import ErrorsActions from '../../Redux/ErrorsRedux'
import errorHandler from '../../Utils/errorHandler'

import './style.less'

const modalProps = {
  centered: true,
  closable: false,
  width: 360,
};

const cancelButtonProps = {
  type: "primary grey",
  className: "btn-primary",
};

const MainPage = props => {
  const { children, isLightBg, user, authError, authFetching, result, modalType, error, fetching } = props;

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    props.setModalType(null);
  }, [0])

  useEffect(() => {
    if (result) {
      message.info('Check your mail box', 3);
    }
  }, [result])

  useEffect(() => {
    if (modalType) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
      props.setRedirect(null);
    }
  }, [modalType])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error])

  useEffect(() => {
    if (authError) {
      errorHandler(authError);
    }
  }, [authError])

  const reopenModal = type => {
    setModalVisible(false);
    props.setModalType(type);
  }

  const ModalContent = {
    login: {
      title: 'log-in',
      children: () => (
        <Spin size="large" spinning={authFetching}>
          <LoginForm
            openForm={reopenModal}
            onSubmit={props.signIn}
          />
        </Spin>
      ),
      okButtonProps: {
        htmlType: 'submit',
        className: 'btn-primary',
        form: 'login-form',
        disabled: authFetching
      }
    },
    signup: {
      title: 'sign-up',
      children: () => (
        <Spin size="large" spinning={authFetching}>
          <SignupForm
            openForm={reopenModal}
            onSubmit={props.signUp}
          />
        </Spin>
      ),
      okButtonProps: {
        htmlType: 'submit',
        className: 'btn-primary',
        form: 'signup-form',
        disabled: authFetching
      }
    },
    forgot: {
      title: 'Forgot password',
      children: () => (
        <Spin size="large" spinning={authFetching}>
          <ForgotPasswordForm
            onSubmit={props.forgotPassword}
          />
        </Spin>
      ),
      okButtonProps: {
        htmlType: 'submit',
        className: 'btn-primary',
        form: 'forgot-password-form',
        disabled: authFetching
      }
    }
  }

  return (
    <div className="container">
      <Spin size="large" wrapperClassName="full-screen" spinning={fetching || authFetching}>
        <Header
          user={user}
          setType={props.setModalType}
          setRedirect={props.setRedirect}
          logout={props.logout}
        />
        <main className="main">
          {children}
        </main >
        <Footer isLightBg={isLightBg} />
        <Modal
          forceRender
          {...modalProps}
          wrapClassName="modal-wrap"
          className="modal"
          title={ModalContent[modalType]?.title}
          visible={modalVisible}
          children={ModalContent[modalType]?.children()}
          okText="Submit"
          onCancel={() => {
            setModalVisible(false);
            props.setModalType(null);
          }}
          cancelText="Cancel"
          okButtonProps={ModalContent[modalType]?.okButtonProps}
          cancelButtonProps={cancelButtonProps}
          destroyOnClose={true}
        />
      </Spin>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    authError: state.auth.error,
    authFetching: state.auth.fetching,
    result: state.auth.forgotPasswordResult,
    modalType: state.auth.modalType,

    fetching: state.main.fetching,

    error: state.errors.data,
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (query) => dispatch(AuthActions.signInRequest(query)),
  signUp: (query) => dispatch(AuthActions.signUpRequest(query)),
  forgotPassword: (query) => dispatch(AuthActions.forgotPasswordRequest(query)),
  setModalType: (modalType) => dispatch(AuthActions.setModalType(modalType)),
  setRedirect: (path) => dispatch(AuthActions.setRedirect(path)),
  logout: () => dispatch(AuthActions.logoutRequest()),

  resetError: () => dispatch(ErrorsActions.errorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
