import React from 'react'
import { useHistory } from 'react-router-dom'

import NavigationMenu from '../NavigationMenu'
import logo from '../../assets/images/logo.svg'
import ExitIcon from '../Icons/ExitIcon'

import './style.less'

const Header = props => {
  const { user, setType, setRedirect, logout } = props;

  const history = useHistory();

  const exit = () => {
    history.push('/');
    logout();
  }

  return (
    <header className="header">
      <NavigationMenu user={user} setType={setType} setRedirect={setRedirect} />
      <img src={logo} alt="" onClick={() => history.push('/')} />
      <div className="header-end">
        {
          user ?
            <>
              <div>{user.email}</div>
              <span onClick={exit}><ExitIcon /></span>
            </>
            :
            <>
              <span onClick={() => setType('login')}>LOG-IN</span>
              <span onClick={() => setType('signup')}>SIGN-UP</span>
            </>
        }
      </div>
    </header>
  );
};

export default Header;
