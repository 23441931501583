import { message } from 'antd'

const errorHandler = (errorObj, resetErrorFn) => {
  if (errorObj.message) {
    switch (typeof errorObj.message) {
      case 'object':
        for (let i in errorObj.message) {
          message.error(`${i}: ${errorObj.message[i]}`, 3);
        }
        break;
      case 'string':
        message.error(errorObj.message, 3);
        break;
      default:
        message.error('Unknown error', 3);
        break;
    }
  } else {
    if (typeof errorObj === 'string') {
      message.error(errorObj, 3);
    } else if (Array.isArray(errorObj)) {
      errorObj.forEach(error => {
        for (let i in error) {
          message.error(`${i}: ${error[i]}`, 3);
        }
      });
    } else if (typeof errorObj === 'object') {
      for (let i in errorObj) {
        if (i !== 'status_code') {
          message.error(`${i}: ${errorObj[i]}`, 3);
        }
      }
    } else {
      message.error('Unknown error', 3);
    }
  }
  resetErrorFn && resetErrorFn();
}

export default errorHandler
