import React, { useState, useEffect, useCallback } from 'react'
import { Slider, Button, Select, Radio, Space, Table, Input, message, Checkbox, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { getUserLocale } from 'get-user-locale';



import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.css';

import CsvIcon from '../../Components/Icons/CsvIcon'
import XlsIcon from '../../Components/Icons/XlsIcon'
import JsonIcon from '../../Components/Icons/JsonIcon'
import AuthActions from '../../Redux/AuthRedux'
import MainActions from '../../Redux/MainRedux'
import utils from '../../Utils/utils'
import countries from '../../StaticData/countryList'
import languages from '../../StaticData/languageList'

import Question from '../../Components/img/question.svg'
import './style.less'

const  getCurrency = () => {
  let lang = getUserLocale()
  console.log('DDSS', lang)
  if (lang.includes('de')) {
    return 'EUR'
  } else {
    return 'USD'
  }
} 

const RootPage = props => {
  const { user, priceList, searchData, result, location } = props;
  const { state } = location;

  const [data, setData] = useState(null);
  const [currentPriceLabel, setCurrentPriceLabel] = useState(null);
  const [price, setPrice] = useState(30);
  const [priceMarks, setPriceMarks] = useState(null);
  const [records, setRecords] = useState(1000);
  const [platform, setPlatform] = useState('instagram');
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [language, setLanguage] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [gender, setGender] = useState(null);
  const [followers, setFollowers] = useState([0, 100]);
  const [growth, setGrowth] = useState(0);
  const [growthER, setGrowthER] = useState(0);
  const [businessSearch, setBusinessSearch] = useState('both');
  const [findMail, setFindMail] = useState(false);
  const [query, setQuery] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let currency = getCurrency()
    props.getPricing({currency: currency});
    if (state?.anchor) {
      utils.openHideLink('#search');
    }
  }, [0])

  useEffect(() => {
    if (country) {
      const index = countries.findIndex(el => el.value === country);
      setCityList(countries[index].children);
    }
  }, [country])

  // Transform table data
  useEffect(() => {
    let arr = searchData?.users ? searchData.users.asMutable({ deep: true }) : [];
    arr = arr.map(el => ({
      id: el._id,
      username_geo: {
        first: el.username,
        second: el.country,
        city: el.city,
      },
      name_bio: {
        first: el.firstName,
        second: el.description,
      },
      followers_engagement: {
        first: utils.formatFollowerCount(el.followers),
        second: el.engagementMean ? (el.engagementMean * 100).toFixed(1) + '%' : null,
      },
      email: el.publicEmail,
    }))
    setData(arr);
  }, [searchData]);

  useEffect(() => {
    const data = {
      platform,
      country,
      city: city.join(),
      language,
      keywords: keywords.join(),
      // gender,
      followerMin: followers[0] === 0 ? null : followerMarks[followers[0]] * 1000,
      followerMax: followers[1] === 100 ? null : followerMarks[followers[1]] * 1000,
      // growthRate: growthMarks[growth].value,
      businessSearch,
      withoutMail: findMail,
      currency: getCurrency()
    };
    if (growthMarks[growth].value) {
      data.growthRate = growthMarks[growth].value
    }
    if (growthMarksEngagementRate[growthER].value) {
      data.engagementMean = growthMarksEngagementRate[growthER].value
    }
    if (gender !== 'n') {
      data.gender = gender
    }
    for (let key in data) {
      if (!data[key]) {
        delete data[key];
      }
    }
    setQuery(data);
  }, [platform, country, city, language, keywords.length, gender, followers[0], followers[1], growth, growthER, businessSearch, findMail])

  useEffect(() => {
    if (query) {
      props.getSearchPreview(query);
    }
  }, [query])

  useEffect(() => {
    if (result) {
      props.getCustomerData();
      message.success('Collection added successfully', 3);
    }
  }, [result])

  useEffect(() => {
    if (priceList) {
      const arr = [...new Set(priceList)];
      let count = Math.ceil(100 / arr.length);
      setCurrentPriceLabel(currentPriceLabel || count * 1);
      const res = {};
      arr.forEach((el, i) => {
        if (i !== 0) {
          let key = i * count;
          if (i === arr.length - 1) {
            key = 100;
          }
          res[key] = {
            style: {
              color: currentPriceLabel === key && '#2B62FF',
              fontSize: currentPriceLabel === key && 18
            },
            label: el.starting_unit < 100000 ? `${el.starting_unit}` : `${el.starting_unit / 1000}k`,
            records: el.starting_unit,
            price: (el.price / 100).toFixed(2),
          }
        }
      })
      setPriceMarks(res);
    }
  }, [priceList, currentPriceLabel])

  const handlePrice = val => {
    setCurrentPriceLabel(val);
    setRecords(priceMarks[val].records);
    let index = priceList.findIndex(el => el.starting_unit === priceMarks[val].records);
    setPrice(priceList[index].price);
  }



  const followerMarks = {
    0: '2',
    7: '5',
    14: '10',
    21: '15',
    35: '25',
    50: '50',
    65: '100',
    85: '500',
    100: '∞'
  };

  const growthMarks = {
    0: {
      label: 'Off',
      value: null
    },
    10: {
      label: '0%',
      value: '0.0'
    },
    20: {
      label: '1%',
      value: '1.0'
    },
    30: {
      label: '2%',
      value: '2.0'
    },
    50: {
      label: '5%',
      value: '5.0'
    },
    75: {
      label: '10%',
      value: '10.0'
    },
    100: {
      label: '15%',
      value: '15.0'
    }
  };

  const growthMarksEngagementRate = {
    0: {
      label: 'Off',
      value: null
    },
    10: {
      label: '1%',
      value: '1.0'
    },
    20: {
      label: '2%',
      value: '2.0'
    },
    30: {
      label: '3%',
      value: '3.0'
    },
    50: {
      label: '5%',
      value: '5.0'
    },
    100: {
      label: '10%',
      value: '10.0'
    },
  };

  const tableColumns = [
    {
      title: `USERNAME
      GEODATA`,
      dataIndex: 'username_geo',
      className: "username_geo",
      render: text => (
        <>
          <div className="blue userName">{text?.first}</div>
          {text?.city
          ? <div className="pre">{`${text?.city}, ${text?.second}`}</div>
          : <div className="pre">{text?.second}</div>}
        </>
      )
    },
    {
      title: `NAME
      BIO`,
      dataIndex: 'name_bio',
      render: text => (
        <>
          <div className="blue">{text?.first}</div>
          <div className="pre">{text?.second}</div>
        </>
      )
    },
    {
      title: `FOLLOWERS
        ENGAGEMENT`,
      dataIndex: 'followers_engagement',
      render: text => (
        <>
          <div className="blue followers">{text?.first}</div>
          <div className="pre">{text?.second}</div>
        </>
      )
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      className: "blue"
    }
  ];

  const changeGrowthCount = data => {
    setGrowth(data);
  }

  const changeGrowthERCount = data => {
    setGrowthER(data);
  }

  const generateFollowerRange = () => {
    if (followers[1] === 100) {
      return `${followerMarks[followers[0]]} K - ∞`
    } else {
      return followers.map(el => followerMarks[el]).join(' - ') + ' K';
    }
  }

  // const handleKeyword = val => {
  //   if (val) {
  //     const res = val.replace(/\s/g, '');
  //     const arr = res.split(',').filter(el => el);
  //     const isError = arr.some(el => el.length > 30);
  //     if (isError) {
  //       message.error('Keyword must contain no more than 30 characters', 3);
  //     } else {
  //       if (arr.length > 5) {
  //         arr.length = 5;
  //       }
  //       setKeywords(arr.join());
  //     }
  //   } else {
  //     setKeywords(null);
  //   }
  // }

  const handleTokenValuesChange = useCallback(
    (newTokenValues) => {
      setKeywords(newTokenValues);
    },
    [keywords]
  );

  const openList = () => {
    if (user) {
      history.push('/exports');
    } else {
      props.setModalType('login');
    }
  }

  const searchPrice = records => {
    const index = priceList?.findIndex(el => el.starting_unit > records);
    if (index >= 0) {
      return (priceList[index - 1]?.price / 100)?.toFixed(2);
    }
  }

  const onChangeFindMail = (e) => {
    setFindMail(e.target.checked);
  };

  return (
    <div className="root-page">
      <div className="img-wrapper">
        <div className="root-bg">
          <div className="root-bg-content">
            <h1>
              World-Wide<br />
              Social Media Data<br />
              <span>in Seconds</span>
            </h1>
            <ul>
              <li>Export influencers & brands in your niche and area</li>
              <li>Over 5 Million Mails from Instagram and TikTok</li>
              <li>Get Mails, Locations and all other public information</li>
              <li>Use one of the best search engines in the world</li>
              <li>Fair Pricing: Never Pay for Duplicates</li>
            </ul>
            {
              priceList &&
              <div className="root-bg-calculate">
                <div className="root-bg-calculate-title">Pricing as simple as it gets</div>
                <span>{`${records} records for`}</span>
                <br />
                <span className="blue">{`${getCurrency()} ${(records * price / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} `}</span>
                <span>{`(${getCurrency()} ${(price / 100).toFixed(2)} per record)`}</span>
                <Slider
                  marks={priceMarks}
                  defaultValue={15}
                  step={null}
                  tooltipVisible={false}
                  onAfterChange={handlePrice}
                  min={15}
                />
              </div>
            }
          </div>
          <div className="root-bg-open-list">
            <div className="title">
              <span></span>
              <div>YOUR EXPORT LIST</div>
              <span></span>
            </div>
            <div className="info">{user && user.tempStats?.noDuplicateCount ? utils.formatValue(user.tempStats?.noDuplicateCount) : 0} RECORDS</div>
            {/* <div className="add-info">{user ? utils.formatValueComma(user.tempStats?.totalPrice) : 0} EUR ({user ? searchPrice(user.tempStats?.noDuplicateCount) : 0} EUR each)</div> */}
            <div className="add-info">
            {getCurrency()} {user?.tempStats?.totalPrice ? (user.tempStats?.totalPrice / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0} {' '}
            ({getCurrency()} {user?.tempStats?.noDuplicateCount ? searchPrice(user.tempStats?.noDuplicateCount) : 0} each)
            </div>
            <Button className="btn-primary green" onClick={openList}>
              open list
            </Button>
          </div>
        </div>
      </div>
      <div className="how-it-works">
        <div>How it works?</div>
        <ul>
          <li>Use the search below to get fitting results</li>
          <li>Check the results and the preview</li>
          <li>Add the results to your Export List</li>
        </ul>
        <ul>
          <li>If needed: Add more data to your Export List</li>
          <li>We won’t add duplicates - you only pay once per data record</li>
          <li>Pay and receive your data in seconds as a downloadable CSV, Excel or JSON file.</li>
        </ul>
      </div>
      <div className="download">
        <div className="title">Download a sample of 500 influencers</div>
        <span></span>
        <Link to={'/files/weafy-csv-sample.csv'} download target="_blank" className='download-link'>
          <div className="download-btn">
              <CsvIcon />
              <span>
                Download CSV
              </span>
          </div>
        </Link>
        <Link to={'/files/weafy-excel-sample.xlsx'} download target="_blank" className='download-link'>
          <div className="download-btn">
            <XlsIcon />
            <span>Download XLS</span>
          </div>
        </Link>
        <Link to={'/files/weafy-json-sample.json'} download target="_blank" className='download-link'>
          <div className="download-btn">
            <JsonIcon />
            <span>Download JSON</span>
          </div>
        </Link>
      </div>
      <div className="active-block" id="search">
        <div className="filters">
          <div className="btn-block">
            <Button
              className={`btn-primary${platform === 'instagram' ? '' : ' grey'}`}
              onClick={() => setPlatform('instagram')}
            >
              instagram search
            </Button>
            <Button
              className={`btn-primary${platform === 'tiktok' ? '' : ' grey'}`}
              onClick={() => {
                setBusinessSearch('both');
                setPlatform('tiktok');
              }}
            >
              tiktok search
            </Button>
          </div>
          <div className="select-block">
            <div className="select-title">country</div>
            <Select
              showSearch
              placeholder="Type or select a country"
              allowClear
              onChange={setCountry}
            >
              {countries.map(el => (
                <Select.Option key={el.value} value={el.value}>{el.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="select-block">
            <div className="select-title">cities</div>
            <Select
              // size={size}
              placeholder="Type a city"
              mode="multiple"
              allowClear
              onChange={setCity}
            >
              {cityList.map(el => (
                <Select.Option key={el} value={el}>{el}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="divider"></div>
          <div className="followers">
            <div className="title">
              <div>followers</div>
              <span>in k</span>
            </div>
            <Slider
              className="range-slider"
              marks={followerMarks}
              defaultValue={[0, 100]}
              step={null}
              tooltipVisible={false}
              range
              onAfterChange={setFollowers}
            />
            <div className="followers-range">{generateFollowerRange()}</div>
          </div>
          <div className="divider"></div>
          <div className="select-block">
            <div className="select-title">language
              <Tooltip title="Type or select a language">
                <img src={Question} role="img" alt="" style={{width: 18}}/>
              </Tooltip>
            </div>
            <Select
              showSearch
              placeholder="Type or select a language"
              allowClear
              onChange={setLanguage}
            >
              {languages.map(el => (
                <Select.Option key={el.value} value={el.value}>{el.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="input-block" style={{marginBottom: 18}}>
            <div className="input-title">keywords
            <Tooltip title="Type keywords">
              <img src={Question} role="img" alt="" style={{width: 18}}/>
            </Tooltip>
            </div>
            {/* <Input onBlur={e => handleKeyword(e.target.value)} bordered/> */}
            <TokenInput
              tokenValues={keywords}
              onTokenValuesChange={handleTokenValuesChange}
            />
          </div>
          <div className="select-block">
            <div className="select-title">gender
            <Tooltip title="Select Gender">
              <img src={Question} role="img" alt="" style={{width: 18}}/>
            </Tooltip>
            </div>
            <Select
              placeholder="Select Gender"
              allowClear
              onChange={setGender}
            >
              <Select.Option value="m">Male</Select.Option>
              <Select.Option value="w">Female</Select.Option>
              <Select.Option value="n">None</Select.Option>
            </Select>
          </div>
          <div className="divider"></div>
          <div className="growth">
            <div className="title">
              <div>minimum growth</div>
              <span>{growthMarks[growth].label}</span>
            </div>
            <Slider
              className="range-slider"
              marks={growthMarks}
              defaultValue={0}
              step={null}
              tooltipVisible={false}
              onAfterChange={changeGrowthCount}
            />
          </div>
          <div className="divider"></div>
          <div className="growth">
            <div className="title">
              <div>Minimum Engagement Rate</div>
              <span>{growthMarksEngagementRate[growthER].label}</span>
            </div>
            <Slider
              className="range-slider"
              marks={growthMarksEngagementRate}
              defaultValue={0}
              step={null}
              tooltipVisible={false}
              onAfterChange={changeGrowthERCount}
            />
          </div>
          <div className="divider"></div>
            <div className="radio-block">
              {platform === 'instagram' &&
                <> 
                <Radio.Group
                  defaultValue={businessSearch}
                  onChange={e => setBusinessSearch(e.target.value)}
                >
                  <Space direction="vertical">
                    <Radio value={'none'}>Only Influencers</Radio>
                    <Radio value={'only'}>Only Brands</Radio>
                    <Radio value={'both'}>Brands and Influencers</Radio>
                  </Space>
                </Radio.Group>
                <div className="divider"></div>
                </>
              }
              <Checkbox className="checkbox-mail" onChange={onChangeFindMail}>Also find accounts without an email</Checkbox>
            </div>
        </div>
        <div className="data-table">
          <div className="action">
            <div>
              <span className="blue">Results: </span>
              {
                searchData?.count ?
                  <span>{utils.formatValue(searchData.count)} Influencers</span>
                  :
                  null
              }
            </div>
            {
              user &&
              <Button className="btn-primary" onClick={() => props.addPreviewToList(query)}>
                add all to a list
              </Button>
            }
          </div>
          <Table
            rowKey={row => row.id}
            dataSource={data}
            columns={tableColumns}
            scrollToFirstRowOnChange
            pagination={false}
            loading={false}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    error: state.auth.error,
    fetching: state.auth.fetching,
    modalType: state.auth.modalType,

    priceList: state.main.priceList,
    searchData: state.main.searchData,
    result: state.main.result,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setModalType: (modalType) => dispatch(AuthActions.setModalType(modalType)),

  getPricing: (query) => dispatch(MainActions.pricingRequest(query)),
  getSearchPreview: (query) => dispatch(MainActions.searchRequest(query)),
  addPreviewToList: (query) => dispatch(MainActions.listAddRequest(query)),
  getCustomerData: () => dispatch(AuthActions.customerDataRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RootPage)
