const AppUtils = {
  toCamelCase(str, firstUpper) {
    const camelCase = str.replace(/_([a-z])/g, (m, w) => w.toUpperCase());
    if (!firstUpper) return camelCase;
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  },

  toPascalCase(str) {
    if (!str) return;
    let arr = str.split('_');
    arr = arr.map(i => (
      this.toUpperLatter(i.toLocaleLowerCase())
    ));
    return arr.join('');
  },

  toUpperLatter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  random() {
    return Math.floor(Math.random() * 0xFFFF);
  },

  formatValue(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  formatValueComma(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatFollowerCount(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    if (val > 1000000) {
      return (val / 1000000).toFixed(2) + 'M';
    } else if (val > 1000) {
      return (val / 1000).toFixed(2) + 'k';
    } else {
      return this.formatValue(val);
    }
  },

  openHideLink(url) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  serialize(obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "." + p : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

};

export default AppUtils;
