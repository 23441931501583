"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenEdit({ tokenValues, onTokenValuesChange, setTokenActivated, handleTokenInputFocus, handleTokenInputBlur, }) {
    const handleTokenEditStart = (0, react_1.useCallback)((targetIndex) => () => {
        setTokenActivated(targetIndex, true);
        handleTokenInputFocus();
    }, [setTokenActivated, handleTokenInputFocus]);
    const handleTokenEditEnd = (0, react_1.useCallback)((targetIndex) => (newTokenValue) => {
        setTokenActivated(targetIndex, false);
        handleTokenInputBlur();
        if (typeof newTokenValue === 'undefined') {
            return;
        }
        const modifiedTokenValues = [...tokenValues];
        modifiedTokenValues[targetIndex] = newTokenValue;
        onTokenValuesChange === null || onTokenValuesChange === void 0 ? void 0 : onTokenValuesChange(modifiedTokenValues);
    }, [tokenValues, onTokenValuesChange, setTokenActivated, handleTokenInputBlur]);
    return {
        handleTokenEditStart,
        handleTokenEditEnd,
    };
}
exports.default = useTokenEdit;
