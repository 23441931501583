import React from 'react'
import { Button } from 'antd'
import { useHistory } from "react-router-dom"

import blog_1 from "../../assets/images/blog-1-image.png"
import blog_2 from "../../assets/images/blog-2-image.png"
import blog_3 from "../../assets/images/blog-3-image.png"

import './style.less'

const BlogsPage = () => {
  const history = useHistory();

  return (
    <div className="blogs-page">
      <div className="blog-item">
        <div className="img">
          <img src={blog_1} alt="" />
        </div>
        <div className="content">
          <div className="title">Dapibus Condimentum Ornare Bibendum Sollicitudin</div>
          <div className="date">Date Published: 31.05.2021</div>
          <div className="description">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod semper....</div>
          <Button className="btn-primary green" onClick={() => history.push('/blog/1')}>
            read more
          </Button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="blog-item">
        <div className="img">
          <img src={blog_2} alt="" />
        </div>
        <div className="content">
          <div className="title">Dapibus Condimentum Ornare Bibendum Sollicitudin</div>
          <div className="date">Date Published: 31.05.2021</div>
          <div className="description">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod semper....</div>
          <Button className="btn-primary green" onClick={() => history.push('/blog/2')}>
            read more
          </Button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="blog-item">
        <div className="img">
          <img src={blog_3} alt="" />
        </div>
        <div className="content">
          <div className="title">Dapibus Condimentum Ornare Bibendum Sollicitudin</div>
          <div className="date">Date Published: 31.05.2021</div>
          <div className="description">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod semper....</div>
          <Button className="btn-primary green" onClick={() => history.push('/blog/3')}>
            read more
          </Button>
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default BlogsPage;
