const RemoveIcon = ({ isActive }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15C4.133 15 1 11.865 1 8C1 4.135 4.133 1 8 1C11.865 1 15 4.135 15 8C15 11.865 11.865 15 8 15Z" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.201 5.79901L5.79901 10.201" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.201 10.201L5.79901 5.79901" stroke="#B5B5B5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RemoveIcon;
