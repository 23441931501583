"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenCreatorRef() {
    const tokenCreatorRef = (0, react_1.useRef)(null);
    const focusTokenCreator = (0, react_1.useCallback)(() => {
        const autosizeInput = tokenCreatorRef === null || tokenCreatorRef === void 0 ? void 0 : tokenCreatorRef.current;
        if (autosizeInput) {
            autosizeInput.getInput().focus();
        }
    }, []);
    return {
        tokenCreatorRef,
        focusTokenCreator,
    };
}
exports.default = useTokenCreatorRef;
