const data = [
  { value: '', label: 'All' },
  { value: 'de', label: 'German' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'it', label: 'Italian' },
  { value: 'da', label: 'Danish' },
  { value: 'ru', label: 'Russian' },
  { value: 'lv', label: 'Latvian' },
  { value: 'cz', label: 'Czech' },
  { value: 'pl', label: 'Polish' },
  { value: 'ar', label: 'Arabic' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'fi', label: 'Finnish' },
  { value: 'ja', label: 'Japanese' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'no', label: 'Norwegian' },
  { value: 'el', label: 'Greek' },
  { value: 'ro', label: 'Romanian' },
  { value: 'tr', label: 'Turkish' }

];

export default data
