import React from 'react'
import { Form, Input } from "antd"

const LoginForm = props => {

  const onFinish = values => {
    const data = { ...values }
    props.onSubmit(data);
  };

  return (
    <Form id="login-form" className="custom-modal-form" onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "This field is required" },
          { type: 'email', message: 'Invalid email format' }
        ]}
      >
        <Input
          placeholder="Email"
          maxLength={50}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "This field is required" },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
        ]}
      >
        <Input.Password
          placeholder="Password"
          maxLength={50}
          autoComplete="new-password"
        />
      </Form.Item>
      <div className="relink">
        <span>Don't have an account yet?</span>
        <a onClick={() => props.openForm('signup')}> Register now</a>
      </div>
      <div className="forgot" onClick={() => props.openForm('forgot')}>Forgot password?</div>
    </Form>
  );
};

export default LoginForm;
