import React from 'react'

import './style.less'

const TermsPage = () => {
  return (
    <div className="terms-page">
      <div className="date">Last Updated: 06.07.2022</div>
      <h1>Terms and Conditions</h1>
      <div className="text">
      <div>
        <div>
          <div>
            <h1>Terms and Conditions</h1>
            <ul>
              <li>
                <h2>1. Introduction</h2>
                <ul>
                  <li>
                    <div>1.1.<p>These terms and conditions relate to our website, products or brands weafy or weafy.com owned by weCreate Data GmbH, Obere Augartenstraße 2/24, 1020 Wien, Österreich (referred as "weCreate Data", "we", "us") and to any other website and product that we own or containing our services or products (our "Website" or "services"). These Terms of Use set out the terms and conditions agreed between us and you (being the person using our Website or - if applicable - customer or user of our services ("you", "your", "customer" or "client" as applicable).</p>
                    </div>
                  </li>
                  <li>
                    <div>1.2.<p>Please read these Terms and Conditions carefully before you start using any section of our Website or purchase any products or services. By using any section of our Website or by registering with us through the Website, you agree to be bound by:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>these Terms and Conditions;</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>the &nbsp;<a href="https://www.weafy.com/privacy/">Privacy Policy</a>;</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>any terms and conditions and or rules with regards to promotions or special offers which may apply from time to time; (together, the "Terms of Use").</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>1.3.<p>If the Terms of Use are translated into any other language it will be for information purposes only and in the event of any conflict between the translation and the German language version, the German language version shall prevail.</p>
                    </div>
                  </li>
                  <li>
                    <div>1.4.<p>Your continued use of the Website or any purchase you conclude will constitute acceptance of the Terms of Use.</p>
                    </div>
                  </li>
                  <li>
                    <div>1.5.<p>The Terms of Use constitute your agreement with us.</p>
                    </div>
                  </li>
                  <li>
                    <div>1.6.<p>In the event that there is any conflict or inconsistency between the terms and conditions of the Terms of Use, the order of precedence shall be as set out in clause 1.2.</p>
                    </div>
                  </li>
                  <li>
                    <div>1.7.<p>We use cookies to give you the best experience on the Website. If you continue to use any section of our Website without changing your cookie settings, we assume that you consent to our use of cookies on the Website. You can change your cookie settings at any time but if you do, you may lose some functionality on our Website. More information can be found in our <a href="https://www.weafy.com/privacy/">Privacy Policy</a>.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>2. Changes to the Terms of Use</h2>
                <ul>
                  <li>
                    <div>2.1.<p>We reserve the right to make changes to the Terms of Use at any time. We will make reasonable efforts to ensure that any significant changes to the Terms of Use will be notified to you by an appropriate method (for example, by email or via a notice on the Website).</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>3. Use of the Website</h2>
                <ul>
                  <li>
                    <div>3.1.<p>You shall use the Website for lawful proposes only.</p>
                    </div>
                  </li>
                  <li>
                    <div>3.2.<p>You shall not be allowed to reproduce the Website or any part of it in any form whatsoever without our express consent.</p>
                    </div>
                  </li>
                  <li>
                    <div>3.3.<p>You must not:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>attempt to gain unauthorised access to the Website, the server on which it is stored or any server, computer or database connected to our Website;</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>you must not attack our Website via a denial-of-service attack or a distributed denial-of service attack; and</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs, time-bombs, keystroke loggers, spyware, adware or any other harmful material designed to adversely affect the operation of any computer software or hardware.</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>3.4<p>By breaching any provision of clause 3.3, you may commit a criminal offence. We may report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing information about you to them. In the event of such a breach, your right to use our Website will cease immediately.</p>
                    </div>
                  </li>
                  <li>
                    <div>3.5<p>You shall not use the Website:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>in any way that breaches any applicable local, national or international law or regulation;</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>for any purpose which is or is likely to be considered to be harmful, threatening, defamatory, libellous, abusive, vulgar, obscene, hateful, racist, sexist, of a discriminatory nature or which could cause offence or is otherwise objectionable;</p>
                        </div>
                      </li>
                      <li>
                        <div>d.<p>for the purpose of harming, threatening or attempting to harm or threaten minors in any way;</p>
                        </div>
                      </li>
                      <li>
                        <div>e.<p>nor to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</p>
                        </div>
                      </li>
                      <li>
                        <div>f.<p>to "stalk" or otherwise harass another;</p>
                        </div>
                      </li>
                      <li>
                        <div>g.<p>to impersonate any person or entity, including, but not limited to, weCreate Data representative, or falsely state or otherwise misrepresent your affiliation with a person or entity;</p>
                        </div>
                      </li>
                      <li>
                        <div>h.<p>to forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Website;</p>
                        </div>
                      </li>
                      <li>
                        <div>i.<p>disrupt the normal flow of dialogue or otherwise act in a manner that negatively affects other users' ability to engage in real time exchanges;</p>
                        </div>
                      </li>
                      <li>
                        <div>j.<p>collect or store personal data about other users of our Website in connection with conduct and activities prohibited by these Terms of Use.</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>3.6<p>You shall not interfere with, damage or disrupt:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>any section of our Website;</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>any network on which our Website is stored;</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>or any software used in the provision of our Website.</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>3.7<p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our Website or to your downloading of any material posted on it, or on any website linked to it.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>4. Registration, Opening &amp; Maintaining Account</h2>
                <ul>
                  <li>
                    <div>4.1.<p>By registering with us, you will open an account ("Account") with us.</p>
                    </div>
                  </li>
                  <li>
                    <div>4.2.<p>When registering and opening an Account with us, you are requested to enter your email and choose a password. You should ensure that these details are kept private as you are responsible for all activity undertaken with your login data (where the correct security information has been provided). If you lose, or forget, your login data, or if you believe that a third party is aware of them, you should contact us immediately so that new security details can be allocated to you. These new details will be emailed to you.</p>
                    </div>
                  </li>
                  <li>
                    <div>4.3.<p>If another person accesses your Account, you are solely responsible for all their actions whether or not their access was authorised by you and you hereby indemnify us and hold us harmless against all costs, claims, expenses and damages arising in connection with the use of or access to your Account by any third party.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>5. Subject of Agreement - weafy.com</h2>
                <ul>
                  <li>
                    <div>5.1.<p>weCreate Data and its brands provide technical services, especially exports of data and analyses of social media profiles, to be used for analytical and marketing purposes. The provided data is publicly accessible and contains mostly business profiles and information extracted from the platforms "instagram.com" or "tiktok.com". The provided data and information is processed automatically and based on estimations and algorithms by weCreate Data or based on data that Instagram and TikTok or its users share publicly.</p>
                    </div>
                  </li>
                  <li>
                    <div>5.2.<p>All business connections and agreements between weCreate Data and the customer are based on these terms and conditions. Deviating terms or agreements - especially the customer's terms - are not applied unless they are approved by weCreate Data and reduced by writing.</p>
                    </div>
                  </li>
                  <li>
                    <div>5.3.<p>weCreate Data is not part of Facebook, Inc., ByteDance or any other social media platform neither are there any business relations existent.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>6. Offers, Contract Closing and Trial</h2>
                <ul>
                  <li>
                    <div>6.1.<p>Offers - either private or publicly available - are non-binding. Orders or acceptions of offers from the customer side need to be reducted by writing or via our payment platform. They are coming into effect only after a written confirmation by weCreate Data and - in case of a subscription - are automatically prolonged if no written termination of the agreement is provided or if the written termination of the agreement is provided only after the cancellation period. Termination notices are only valid reduced to writing or via our platform cancellation services.</p>
                    </div>
                  </li>
                  <li>
                    <div>6.2.<p>These terms and conditions apply to any offer and service and are always meant to be the legal basis. Divergent or additional agreements need to be reduced to writing especially conditions provided by the customer. Such condition will not apply even if they are not explicitly rejected by weCreate Data.</p>
                    </div>
                  </li>
                  <li>
                    <div>6.3.<p>The services of weCreate Data start with creating an account by entering your email address and password. Any paid subscriptions will be confirmed in written form via email (e.g. "Start of Trial") after you enter your billing details and confirm your order summary. In case of a subscription, we will prolong and charge your subscription automatically for another term, if the subscription is not cancelled at all or not cancelled in time (see 6.)</p>
                    </div>
                  </li>
                  <li>
                    <div>6.4.<p>You or the company you represent can take advantage of our "Free Trial" offer only once every 12 month, if nothing else is confirmed from our side in written form. If you start another "Free Trial" within the period of 12 months, weCreate Data reserves the right to charge the costs for the first month from your payment method, if available.</p>
                    </div>
                  </li>
                  <li>
                    <div>6.5.<p>With the completion of your order you confirm that you have - in case you represent a company - the permissions required under applicable law to conclude contracts on behalf of the company. Besides that you confirm that you are authorised to use the specified payment method for any payments regarding weCreate Data and also that the payment method is valid.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>7. Specification of Services</h2>
                <ul>
                  <li>
                    <div>7.1.<p>The scope of the services equals the offer provided by "weafy.com" and is confirmed by the written order confirmation of weCreate Data. Subsequent changes of provided services require a written confirmation of weCreate Data. In case the delivered or provided product is not equal to the offered or ordered product, the customer must inform weCreate Data immediately after knowledge.</p>
                    </div>
                  </li>
                  <li>
                    <div>7.2.<p>All provided data is non committal and provided without liability, originated from publicly available sources and there is a high probability that information or data is flawed. Certain parts of the provided data is based on estimations and may not be up to date. The services of weCreate Data should only be used as an assistance and not be trusted completely. Claims of any kind in regards to the accuracy of the provided data are prohibited.</p>
                    </div>
                  </li>
                  <li>
                    <div>7.3.<p>The customer must use the weCreate Data services only for the designated purposes. Unless there are divergent written agreements, these purposes only cover the advertisement and marketing of the customer's products. It is particularly forbidden to use any service provided by weCreate Data for the following purposes:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>Mass-mailing or mass-messaging any contact provided by weCreate Data (e.g. Spam).</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>Usage of the services or data provided by weCreate Data in any way that it could violate national or international laws. In particular this includes laws by the customer's nation of residence, laws by the weCreate Data state of residence, or laws by the nation of residence of any third party that is involved.</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>Automated or partly automated usage of any part of the service.</p>
                        </div>
                      </li>
                      <li>
                        <div>d.<p>Transferring any data or information provided by weCreate Data to any third party outside your organization (unless there is written approval by weCreate Data)</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>7.4.<p>The customer is obligated to provide access to any information that is required for weCreate Data to fulfill the services offered and purchased by the customer.</p>
                    </div>
                  </li>
                  <li>
                    <div>7.5.<p>The customer is obligated to verify that at any time no copyrights or other rights of third parties are violated.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>8. Liability &amp; warranties</h2>
                <ul>
                  <li>
                    <div>8.1.<p>weCreate Data guarantees that the services provided comply with the agreed service description. weCreate Data does not assume any warranty for third-party services, such as error-free and secure network operation. Any claims for damages on the part of the customer shall be made in writing by the customer - without prejudice to statutory or contractually agreed deadlines - within the minimum of the terms required by law of knowledge of the damage and the injuring party, otherwise they shall expire. The customer's obligation to examine and give notice of defects remains entirely unaffected.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.2.<p>As an error in the performance of weCreate Data only reproducible errors are considered, which originate from a qualitatively deficient performance on the part of weCreate Data. Other causes, such as faulty operation, external optimization measures and changes in content, defective hardware, adverse environmental conditions or poor data quality shall not be regarded as errors within the meaning of the warranty.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.3.<p>The risk of the legal permissibility of the measures developed and implemented by weCreate Data is carried by the customer. This applies in particular in the event that the actions and measures contravene provisions of competition law, copyright law and specific advertising laws. The customer exempts weCreate Data from such claims of third parties. This also includes legal prosecution costs in the event that weCreate Data may use third parties. weCreate Data shall notify the customer of such concerns in writing immediately after becoming aware of them. If weCreate Data considers a competition law examination by a particularly competent person or institution to be necessary for a measure to be carried out, the costs for this shall be carried by the customer after consultation with weCreate Data.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.4.<p>weCreate Data is only liable for damages that it or its vicarious agents have caused intentionally or through gross negligence. The liability of weCreate Data is limited to the typical damage to be expected by weCreate Data for the service in question. This limitation of liability shall also apply to consequential and indirect damages in the event of simple negligence, except for death, personal injury or damage to health.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.5.<p>weCreate Data does not guarantee the full avoidance of downtime or loss of revenue.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.6.<p>Excluded from the warranty are those errors that are caused by external influences, operating errors or changes, additions, installations or removals not carried out by weCreate Data, repair attempts by the customer or other handling.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.7.<p>The services - in particular the data provided by weCreate Data - can contain errors and no guarantee can be given on correctness. The client himself is obliged to check the data for correctness. Furthermore, weCreate Data assumes no liability for damages resulting from incorrect data or information provided. Please note that the data provided is either based on automated estimates and algorithms or provided by Instagram, TikTok or its users. This information is not checked manually.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.8.<p>If weCreate Data is proven to have acted with gross negligence, liability for damages shall be limited to the amount of the net order value of the previous month.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.9.<p>weCreate Data will not pay compensation if the cause of a breach of duty lies outside the responsibility of weCreate Data, for example because third parties do not provide the required supplier services properly or technical problems occur for which third parties are responsible.</p>
                    </div>
                  </li>
                  <li>
                    <div>8.10.<p>The customer is liable for damages to weCreate Data caused intentionally or through gross negligence and based on an improper use of the services. In particular, the customer shall be liable for damages or lost profits caused by the transfer of the gained data or access data to third parties. The customer shall also be liable for any damage caused by system failures if these are due to improper use.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>9. Pricing, payments &amp; due dates</h2>
                <ul>
                  <li>
                    <div>9.1.<p>All prices are net prices plus statutory sales tax, unless otherwise stated.</p>
                    </div>
                  </li>
                  <li>
                    <div>9.2.<p>The invoice is issued after successful payment.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>10. Contribution</h2>
                <ul>
                  <li>
                    <div>10.1.<p>All information and other cooperation services required for the performance of the contractually agreed services must be made available or granted to weCreate Data by the customer.</p>
                    </div>
                  </li>
                  <li>
                    <div>10.2.<p>The quality of the services provided by weCreate Data must be immediately evaluated by the customer, any deficiencies found must be immediately presented to weCreate Data in writing and in detail. A period of 14 days from the provision of the service shall apply for notification of faults.</p>
                    </div>
                  </li>
                  <li>
                    <div>10.3.<p>If the customer does not fulfill his obligations to cooperate, weCreate Data is also exempt from the obligation to provide services.</p>
                    </div>
                  </li>
                  <li>
                    <div>10.4.<p>Unless otherwise agreed in writing, weCreate Data has the right, for the purpose of its own marketing, to publish an existing contractual relationship by naming the client and in presentation documents. In addition, both parties undertake to maintain silence regarding details of the contractual relationship.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>11. Confidentiality and data protection</h2>
                <ul>
                  <li>
                    <div>11.1.<p>The use of the service is permitted only to the registered user. A passing on of the registration data to third parties is prohibited.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.2.<p>weCreate Data is obliged to treat all knowledge which it receives from the customer on the basis of an order as strictly confidential for an unlimited period of time and to obligate both its employees and third parties engaged by it to absolute confidentiality in the same manner.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.3.<p>The customer undertakes to keep secret all business and company secrets or information designated as confidential that he receives or becomes aware of during the execution of the contract by weCreate Data or persons acting on behalf of weCreate Data. This applies in particular to information about algorithms and technologies of the social media platforms, as far as these are not generally known or also data and information which have become known to the customer through the service. This obligation also applies for two years after the termination of the contract.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.4.<p>weCreate Data stores all data of the customer electronically during the duration of the contractual relationship, as far as this is necessary for the fulfillment of the contract purpose, in particular for accounting purposes. In addition, usage data (time of entry, input values, number of entries, user's IP address, user's data stored on the platform, user's data exports, etc.) is stored and processed in order to improve products, find and eliminate errors in the software, or to detect misuse. weCreate Data also processes and uses the collected data anonymously for advising its customers, for advertising and market research for its own purposes and for the demand-oriented design of its telecommunications services. weCreate Data will provide the customer at any time on request with complete and free information about the stored data stock, as far as he is concerned, and delete all stored personal data at the customer's request.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.5.<p>The stored inventory data includes the personal data, namely name/company, profession, date of birth, company register number, powers of representation, contact person, business address and other addresses of the customer, telephone number, fax number, e-mail address, bank details and VAT number, as well as the access data ('login') of the company.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.6.<p>weCreate Data will neither forward this data nor the content of private messages from the customer to third parties without the customer's consent. This does not apply to the extent that weCreate Data is obliged to disclose such data to third parties, in particular government agencies, or to the extent that internationally recognized technical standards provide for this and the customer does not disagree.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.7.<p>weCreate Data expressly indicates to the customer that data protection for data transmissions in open networks such as the Internet cannot be comprehensively guaranteed according to the current state of technology.</p>
                    </div>
                  </li>
                  <li>
                    <div>11.8.<p>The customer knows that the provider can view the website stored on the web server and, under certain circumstances, other customer data stored there at any time from a technical point of view. Other Internet users may also be technically able to interfere with network security without authorization and control message traffic. The customer is fully responsible for the security of the data transmitted by him to the Internet and stored on web servers.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>12. Fraud</h2>
                <ul>
                  <li>
                    <div>12.1.<p>We reserve the right to seek criminal and contractual sanctions against you if you are involved in fraud, dishonest or criminal acts and will make such reports as necessary to the authorities.</p>
                    </div>
                  </li>
                  <li>
                    <div>12.2.<p>You shall indemnify and shall be liable to pay us, on demand, all costs, charges or losses sustained or incurred by us (including any direct, indirect or consequential losses, loss of profit and loss of reputation) arising directly or indirectly from your fraud, dishonesty or criminal actions.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>13. Disputes</h2>
                <ul>
                  <li>
                    <div>13.1.<p>If you wish to make a query or complaint regarding the Website, our products or services, as a first step you should as soon as reasonably practicable contact our Customer Support Team.</p>
                    </div>
                  </li>
                  <li>
                    <div>13.2.<p>If, after having dealt with a member of the Customer Support Team, your query or complaint is not resolved, you can ask for the query/complaint to be escalated to a manager or supervisor. Our manager/supervisor will look into your query/complaint in more detail and will contact you as soon as practically possible. You will be given the name and status of the person who your query/complaint has been referred to.</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <h2>14. General</h2>
                <ul>
                  <li>
                    <div>14.1.<p>Entire Agreement. These Terms of Use and any document expressly referred to in them and any guidelines or rules posted on our Website constitute the entire agreement and understanding between you and us in relation to the subject matter of the Terms of Use and supersede any prior agreement, understanding or arrangement between us, whether oral or in writing. You acknowledges and agree that in accepting these Terms of Use you do not rely on, and shall have no remedy in respect of, any statement, representation, warranty, understanding, promise or assurance (whether negligently or innocently made) of any person other than as expressly set out in these Terms of Use. Nothing in this clause shall operate to limit or exclude any liability for fraud, fraudulent or negligent misrepresentation.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.2.<p>Waiver. No delay, failure or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or remedy conferred by or arising under these Terms of Use or by law, be deemed to be or construed as a waiver of the same.Similarly, any single or partial exercise or pursuance of such right, power, privilege, claim or remedy, shall not preclude any further exercise of any of the same or the exercise of any other right, other right, power, privilege, claim or remedy.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.3.<p>Assignment, Transfer, etc. You may not assign, transfer, charge, licence, sub-licence or otherwise deal in your rights and/or obligations under the Terms of Use without our prior written consent. We are entitled to assign, transfer, charge or otherwise deal in our rights under these Terms of Use as we see fit.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.4.<p>Severability. If any of the Terms of Use are determined by any court, administrative body or other competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining Terms and Conditions which will continue to be valid to the fullest extent permitted by law.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.5.<p>Notices. Any notices given or made under these Terms of Use shall be in writing and shall be delivered as follows:</p>
                    </div>
                    <ul>
                      <li>
                        <div>a.<p>Notices to you shall be sent by email to the relevant email address provided by you when opening an Account with us or by any of the methods set out in clause 28.5(b)(1.2. a.) to (1.2. b.) inclusive.</p>
                        </div>
                      </li>
                      <li>
                        <div>b.<p>If you have not opened an Account with us, notices to you shall be delivered: (1.2. a.) by email to any email address you may have used to communicate with us, (1.2. b.) by post, courier or other form of physical delivery to your home or place of work or (1.2. c.) by personal delivery to you.</p>
                        </div>
                      </li>
                      <li>
                        <div>c.<p>Notices to us shall be sent by email to Customer Support Team or to the registered address of weCreate Data GmbH, Obere Augartenstraße 2/24, 1020 Wien, Austria.</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>14.6.<p>Third Party Rights. Unless expressly stated, nothing in the Terms of Use shall create or confer any rights or any other benefits whether pursuant to the English Contracts (Rights of Third Parties) Act 1999 or otherwise in favour of any person other than you and us.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.7.<p>No Partnership, Agency, etc. Nothing in the Terms of Use shall be construed as creating any agency, partnership or any other form of joint enterprise between you and us nor to authorise either to act as agent for the other and neither you or us shall have authority to act in the name of or on behalf of or otherwise to bind the other in any way.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.8.<p>Force Majeure. Neither you nor us shall be liable to the other in respect of anything which, apart from this provision, may constitute breach of these Terms of Use arising by reason of force majeure, namely circumstances beyond the control of either you or us which shall include (but not be limited to) acts of God, terrorism, perils of the sea or air, fire, flood, drought, explosion, embargo, riots, labour strikes, civil commotion or civil authority including acts of local government and parliamentary authority, terrorist attacks, global computer viruses which affect the internet backbone.</p>
                    </div>
                  </li>
                  <li>
                    <div>14.9.<p>Governing Law &amp; Jurisdiction. The validity, construction and performance of these Terms of Use (and any claim, dispute or matter arising under or in connection with it or its enforceability) shall be governed by and construed in accordance with the laws of Austria. We and you irrevocably submit to the exclusive jurisdiction of the Austria courts over any claim, dispute or matter arising under or in connection with this Agreement or its enforceability.</p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <footer>
            <div><br /></div>
          </footer>
        </div>
      </div>
      </div>
    </div>
  );
};

export default TermsPage;
