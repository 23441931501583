import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Table, Popconfirm, Modal, Spin, Input, Form, Checkbox } from 'antd'
import moment from 'moment'

import CsvIcon from '../../Components/Icons/CsvIcon'
import XlsIcon from '../../Components/Icons/XlsIcon'
import JsonIcon from '../../Components/Icons/JsonIcon'
import InvoiceIcon from '../../Components/Icons/InvoiceIcon'
import RemoveIcon from '../../Components/Icons/RemoveIcon'
import EditIcon from '../../Components/Icons/EditIcon'
import utils from '../../Utils/utils'
import MainActions from '../../Redux/MainRedux'
import AuthActions from '../../Redux/AuthRedux'

import { getUserLocale } from 'get-user-locale';


import './style.less'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const cancelButtonProps = {
  type: "primary grey",
  className: "btn-primary",
};
const  getCurrency = () => {
  let lang = getUserLocale()
  if (lang.includes('de')) {
    return 'EUR'
  } else {
    return 'USD'
  }
} 

const ExportsPage = props => {
  const { userInfo, result, fetching, saveFetching, checkoutResult, exportResult } = props;

  const [collectionData, setCollectionData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [search, setSearch] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  //state for checkboxes in popup Terms and Conditions before checkout
  const [termsCheked_1, setTermsCheked_1] = useState(false);
  const [termsCheked_2, setTermsCheked_2] = useState(false);
  const [termsCheked_3, setTermsCheked_3] = useState(false);
  const [termsCheked_4, setTermsCheked_4] = useState(false);


  const history = useHistory();

  useEffect(() => {
    props.getCustomerData();
    // Extract and transform search params from url after payment
    if (document.location.search) {
      let params = document
        .location
        .search
        .replace('?', '')
        .split('&')
        .reduce(
          function (p, e) {
            var a = e.split('=');
            p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
            return p;
          },
          {}
        );
      setSearch(params);
    }
  }, [0])

  // Handling of payment status
  useEffect(() => {
    if (search) {
      if (search.id && search.state === 'succeeded') {
        props.createExportAfterPayment({ hostedPageId: search.id });
      } else if (search.state === 'cancelled') {
        setModalType('cancel');
      }
    }
  }, [search])

  useEffect(() => {
    if (modalType) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [modalType])

  // Transform tables data
  useEffect(() => {
    if (userInfo?.tempCollections) {
      let arr = userInfo?.tempCollections ? userInfo.tempCollections.asMutable({ deep: true }) : [];
      arr = arr.map(el => ({
        id: el.collId,
        comment: el.comment,
        filter: el.description,
        count: el.count,
      }))
      setCollectionData(arr);
      setTotalCount(arr.reduce((acc, el) => acc + el.count, 0))
    }
    if (userInfo?.purchasedExports) {
      let arr = userInfo?.purchasedExports ? userInfo.purchasedExports.asMutable({ deep: true }) : [];
      arr = arr.map(el => ({
        id: el.hostedPageId,
        date: el.date,
        count: el.billedRecords,
        amount: el.billedAmount,
        links: {
          csv: el.csvLink,
          xls: el.xlsLink,
          json: el.jsonLink,
          invoice: el.chargebeeInvoice,
        }
      }))
      setExportData(arr);
    }
  }, [userInfo]);

  useEffect(() => {
    if (result) {
      props.getCustomerData();
      setModalVisible(false);
      setModalType(null);
    }
  }, [result])

  useEffect(() => {
    if (checkoutResult) {
      setModalVisible(false);
      setModalType(null);
      utils.openHideLink(checkoutResult.url);
      props.getCustomerData();
    }
  }, [checkoutResult])

  useEffect(() => {
    if (exportResult) {
      setModalType('success');
    }
  }, [exportResult])

  const generateFilterContent = data => {
    if (data) {
      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      return (
        <>
          {
            Object.entries(data).map(el => (
              <div key={el[0]}>
                <span className="blue">{el[0]}: </span>
                <span className="filter-value">{el[1]}</span>
              </div>
            ))
          }
        </>
      )
    } else return null;
  }

  const userTableColumns = [
    {
      title: `YOUR COMMENTS
      (WILL BE EXPORTED)`,
      dataIndex: 'comment',
      render: text => (
        <div className="blue">{text}</div>
      )
    },
    {
      title: 'FILTER',
      dataIndex: 'filter',
      render: text => generateFilterContent(text)
    },
    {
      title: 'RESULTS COUNT',
      dataIndex: 'count',
      render: text => (
        <div className="blue">{utils.formatValue(text)} Contacts</div>
      )
    },
    {
      title: 'OPTIONS',
      dataIndex: 'id',
      render: (text, record) => (
        <>
          <div
            className="download-btn remove silver"
            style={{ marginBottom: 12 }}
            onClick={() => {
              setModalType('comment');
              setCurrentRecord(record);
            }}
          >
            <EditIcon />
            <span>Edit Comment</span>
          </div>
          <Popconfirm
            title="Sure to delete?"
            okButtonProps={{ className: "btn-primary" }}
            cancelButtonProps={{ className: "btn-primary grey" }}
            onConfirm={() => props.removeList({ collIds: text, currency: getCurrency() })}
          >
            <div className="download-btn remove">
              <RemoveIcon />
              <span>Remove</span>
            </div>
          </Popconfirm>
        </>
      )
    }
  ];

  const exportTableColumns = [
    {
      title: 'DATE',
      dataIndex: 'date',
      render: (text, record) =>
        <div className="date-cell">
          <div className="blue">{text && moment(text).format('LL')}</div>
          <div className="time">
            <span className="line"></span>
            <span>{text && moment(text).format('LT')}</span>
          </div>
        </div>
    },
    {
      title: 'EXPORT COUNT',
      dataIndex: 'count',
      render: text => (
        <div className="blue">{utils.formatValue(text)} Contacts</div>
      )
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      render: text => (
        // <div className="blue">{utils.formatValue(text) ? utils.formatValue(text) + ' EUR' : null}</div>
        <div className="blue">{getCurrency()} {text ? (text / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</div>
      )
    },
    {
      title: 'OPTIONS',
      dataIndex: 'links',
      width: 230,
      render: (text) => (
        <div className="options-block">
          {(!text.csv && !text.xls && !text.json && !text.invoice) && <div className='in_progress'>In Progress</div>}
          {
            text.csv &&
            <a href={text.csv} download className="download-btn" target="_blank" rel="noreferrer">
              <CsvIcon />
              <span>Download CSV</span>
            </a>
          }
          {
            text.xls &&
            <a href={text.xls} download className="download-btn" target="_blank" rel="noreferrer">
              <XlsIcon />
              <span>Download XLS</span>
            </a>
          }
          {
            text.json &&
            <a href={text.json} download className="download-btn" target="_blank" rel="noreferrer">
              <JsonIcon />
              <span>Download JSON</span>
            </a>
          }
          {
            text.invoice &&
            <div
              onClick={()=> props.getInvoice({ invoiceNumber: text.invoice })}
              // download 
              className="download-btn" 
              // target="_blank" 
              // rel="noreferrer"
            >
              <InvoiceIcon />
              <span>Download Invoice</span>
            </div>
          }
        </div>
      )
    }
  ];

  const editComment = values => {
    const data = { ...values }
    data.collId = currentRecord.id;
    props.renameList(data);
  };

  //for checkboxes in popup privacy policy
  const onChange_1 = (e) => {
    setTermsCheked_1(e.target.checked)
  };
  const onChange_2 = (e) => {
    setTermsCheked_2(e.target.checked)
  };
  const onChange_3 = (e) => {
    setTermsCheked_3(e.target.checked)
  };
  const onChange_4 = (e) => {
    setTermsCheked_4(e.target.checked)
  };

  const initiateCheckOut = (type) => {
    if (termsCheked_1 && termsCheked_2 && termsCheked_3 && termsCheked_4) {
      type === 'ContinueWithD' 
        ? props.initiateCheckout({ exportDuplicates: true , currency: getCurrency()}) 
        : props.initiateCheckout({ exportDuplicates: false, currency: getCurrency() });
        
      setTermsCheked_1(false);
      setTermsCheked_2(false);
      setTermsCheked_3(false);
      setTermsCheked_4(false);
    }
  }

  const ModalContent = {
    comment: {
      title: 'Edit comment',
      children: () => (
        <Spin size="large" spinning={saveFetching}>
          <Form id="edit-comment-form" className="custom-modal-form" initialValues={{ comment: currentRecord?.comment }} onFinish={editComment}>
            <Form.Item
              name="comment"
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      ),
      modalProps: {
        centered: true,
        closable: false,
        width: 360,
      },
      okButtonProps: {
        htmlType: 'submit',
        className: 'btn-primary',
        form: 'edit-comment-form',
        disabled: saveFetching
      },
      onCancel: () => setModalType(null)
    },
    no_checkout: {
      children: () => (
        <>
        <ExclamationCircleOutlined />
        <div></div>
        <div>The minimum amount of records to export is 500. Please add more users to your list.</div>
        </>
      ),
      modalProps: {
        centered: true,
        closable: true,
        width: 480,
      },
      footer: null,
      onCancel: () => setModalType(null)
    },
    checkout: {
      title: 'Confirm Checkout',
      children: () => (
        <Spin size="large" spinning={fetching}>
          <div className="checkout-text">You will be redirected to our payment page and we will start your export as soon as the payment is successful.</div>
          <br />
          <div className="checkout-text">Your export will appear here once it's finished. Please keep in mind that the export can take up to one hour to finish.</div>
          <br />
          <div className="checkout-text">Do you want to include possible duplicates in your exports? Duplicates will never be charged.</div>
          <div className="checkout-footer">
            <div onClick={() => setModalType('ContinueWithD')}>Continue with duplicates</div>
            <div onClick={() => setModalType('ContinueWithoutD')}>Continue without duplicates</div>
            {/* <div onClick={() => props.initiateCheckout({ exportDuplicates: true })}>Continue with duplicates</div>
            <div onClick={() => props.initiateCheckout({ exportDuplicates: false })}>Continue without duplicates</div> */}
          </div>
        </Spin>
      ),
      modalProps: {
        centered: true,
        width: 480,
      },
      footer: null,
      onCancel: () => setModalType(null)
    },
    ContinueWithD: {
      title: 'Terms & Conditions',
      children: () => (
        <Spin size="large" spinning={fetching}>
          <div className="checkout-text">Please carefully read and accept out Terms & Conditions and Privacy Policy</div>
          <br />
          <Checkbox onChange={onChange_1}>I understand and accept the Terms & Conditions</Checkbox>
          <br />
          <br />
          <Checkbox onChange={onChange_2}>I understand and accept the Privacy Policy</Checkbox>
          <br />
          <br />
          <Checkbox onChange={onChange_3}>I understand that I am solely responsible for using the data in a way that is compliant
           with the laws of my country and any other laws that might be affected (e.g. GDPR)"</Checkbox>
           <br />
           <br />
          <Checkbox onChange={onChange_4}>I understand that weCreate Data GmbH is only a data provider and is not liable for any 
           damages to me or any other party that result from any form of utilizing this data.</Checkbox>
           <br />
           <br />
           <div className="checkout-text" style={{ fontSize: 12 }}>* All checkboxes must be clicked to continue</div>
          <div className="checkout-footer">
            <div onClick={()=>initiateCheckOut('ContinueWithD')}>
                Continue
            </div>
          </div>
        </Spin>
      ),
      modalProps: {
        centered: true,
        width: 480,
      },
      footer: null,
      onCancel: () => setModalType(null)
    },
    ContinueWithoutD: {
      title: 'Terms & Conditions',
      children: () => (
        <Spin size="large" spinning={fetching}>
          <div className="checkout-text">Please carefully read and accept out Terms & Conditions and Privacy Policy</div>
          <br />
          <Checkbox onChange={onChange_1}>I understand and accept the Terms & Conditions</Checkbox>
          <br />
          <br />
          <Checkbox onChange={onChange_2}>I understand and accept the Privacy Policy</Checkbox>
          <br />
          <br />
          <Checkbox onChange={onChange_3}>I understand that I am solely responsible for using the data in a way that is compliant
           with the laws of my country and any other laws that might be affected (e.g. GDPR)"</Checkbox>
           <br />
           <br />
          <Checkbox onChange={onChange_4}>I understand that weCreate Data GmbH is only a data provider and is not liable for any 
           damages to me or any other party that result from any form of utilizing this data.</Checkbox>
           <br />
           <br />
           <div className="checkout-text" style={{ fontSize: 12 }}>* All checkboxes must be clicked to continue</div>
          <div className="checkout-footer">
            <div onClick={()=>initiateCheckOut('ContinueWithoutD')}>
                Continue
            </div>
          </div>
        </Spin>
      ),
      modalProps: {
        centered: true,
        width: 480,
      },
      footer: null,
      onCancel: () => setModalType(null)
    },
    success: {
      children: () => (
        <div className="info-modal">
          <div className="top">Thank you for your payment.</div>
          <br />
          <div>We take care of your request now and the export process will take up to two hours. It will appear automatically in your exports section and we will notify you, once your export is ready.</div>
          <br />
          <div>Feel free to contact us, if you have any questions.</div>
        </div>
      ),
      modalProps: {
        centered: true,
        width: 480,
      },
      footer: null,
      onCancel: () => {
        setModalType(null);
        history.push('/exports');
        props.removeList({ collIds: userInfo?.tempCollections?.map(el => el.collId).join(), currency: getCurrency() })
      }
    },
    cancel: {
      children: () => (
        <div className="info-modal">
          <div>Sorry, there were issues with your payment. Please try again.</div>
          <br />
          <div>If this happens more often and you have problems finalising your order, please contact our support. Thank You!</div>
          <br />
          <div>Feel free to contact us, if you have any questions.</div>
        </div>
      ),
      modalProps: {
        centered: true,
        width: 480,
      },
      footer: null,
      onCancel: () => {
        setModalType(null)
        history.push('/exports')
      }
    },
  }

  return (
    <div className="exports-page">
      <div className="table-block">
        <div className="info">
          <div className="title">Users in your Current List</div>
          <div className="data">
            <div>
              <span>List Total: </span>
              <span className="blue">{utils.formatValue(userInfo?.tempStats?.totalCount)}</span>
            </div>
            <div>
              <span>Results without Duplicates (billed): </span>
              <span className="blue">{utils.formatValue(userInfo?.tempStats?.noDuplicateCount)}</span>
            </div>
            <div>
              <span>Total Amount (net): </span>
              <span className="blue">{getCurrency()} {(userInfo?.tempStats?.totalPrice / 100)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
            </div>
          </div>
        </div>
        <Table
          rowKey={row => row.id}
          dataSource={collectionData}
          columns={userTableColumns}
          scroll={{ y: 600 }}
          pagination={false}
          loading={false}
        />
        <div className="action">
          <Button
            className="btn-primary green"
            onClick={() => history.push({
              pathname: '/',
              state: {
                anchor: true
              }
            })}
          >
            add more users
          </Button>
          <Button className="btn-primary green" 
            disabled={!userInfo?.tempCollections?.length} 
            onClick={() => setModalType(userInfo?.tempStats?.noDuplicateCount >= 500 ? 'checkout' : 'no_checkout')}
          >
            checkout
          </Button>
        </div>
      </div>
      <div className="table-block">
        <div className="info">
          <div className="title">Your Exports</div>
        </div>
        <Table
          rowKey={row => row.id}
          dataSource={exportData}
          columns={exportTableColumns}
          scroll={{ y: 600 }}
          pagination={false}
          loading={false}
        />
      </div>
      <Modal
        forceRender
        {...ModalContent[modalType]?.modalProps}
        wrapClassName="modal-wrap"
        className="modal"
        title={ModalContent[modalType]?.title}
        visible={modalVisible}
        children={ModalContent[modalType]?.children()}
        footer={ModalContent[modalType]?.footer}
        okText="Submit"
        onCancel={ModalContent[modalType]?.onCancel}
        cancelText="Cancel"
        okButtonProps={ModalContent[modalType]?.okButtonProps}
        cancelButtonProps={cancelButtonProps}
        destroyOnClose={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,

    result: state.main.result,
    fetching: state.main.fetching,
    saveFetching: state.main.saveFetching,
    checkoutResult: state.main.checkoutResult,
    exportResult: state.main.exportResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCustomerData: () => dispatch(AuthActions.customerDataRequest()),

  renameList: (query) => dispatch(MainActions.listEditRequest(query)),
  removeList: (query) => dispatch(MainActions.listDeleteRequest(query)),
  initiateCheckout: (query) => dispatch(MainActions.checkoutRequest(query)),
  createExportAfterPayment: (query) => dispatch(MainActions.exportRequest(query)),
  getInvoice: (query) => dispatch(MainActions.getInvoiceRequest(query)),

})

export default connect(mapStateToProps, mapDispatchToProps)(ExportsPage)
