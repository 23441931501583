const EditIcon = ({ isActive }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 1.45455C4.38505 1.45455 1.45455 4.38505 1.45455 8C1.45455 11.615 4.38505 14.5455 8 14.5455C11.615 14.5455 14.5455 11.615 14.5455 8C14.5455 4.38505 11.615 1.45455 8 1.45455ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#000000" />
      <path d="M10.0599 5.87215C10.1291 5.83868 10.193 5.77481 10.3208 5.64707L10.3213 5.64659C10.4487 5.51916 10.5125 5.45536 10.5459 5.38618C10.6062 5.26126 10.6062 5.11561 10.5459 4.99068C10.5125 4.92151 10.4487 4.85772 10.3213 4.73033L9.77041 4.17944C9.64268 4.0517 9.57882 3.98783 9.50955 3.95436C9.38459 3.894 9.23895 3.894 9.11405 3.95436C9.04482 3.98781 8.98099 4.05162 8.85337 4.17921C8.72564 4.30696 8.66155 4.37106 8.62805 4.44033C8.56773 4.56525 8.56773 4.7109 8.62805 4.83582C8.66155 4.90509 8.72541 4.96896 8.85314 5.09671L9.4035 5.64707C9.53112 5.77466 9.59518 5.8387 9.66441 5.87215C9.78932 5.93251 9.93496 5.93251 10.0599 5.87215Z" fill="#000000" />
      <path d="M5.04222 9.45818C5.17536 9.59132 5.38963 9.59132 5.81818 9.59132H6.00016C6.18595 9.59132 6.27885 9.59132 6.36238 9.55673C6.44591 9.52209 6.51161 9.45641 6.64298 9.32504L8.85259 7.11545C9.15564 6.8124 9.30714 6.66088 9.30714 6.47261C9.30714 6.28433 9.15564 6.13281 8.85259 5.82978L8.67064 5.64781C8.36766 5.34484 8.21607 5.19326 8.02777 5.19326C7.83952 5.19326 7.68803 5.34474 7.38505 5.64771L5.17536 7.85741C5.04398 7.98877 4.97829 8.0545 4.94369 8.138C4.90909 8.22154 4.90909 8.31445 4.90909 8.50023V8.68223C4.90909 9.11077 4.90909 9.32504 5.04222 9.45818Z" fill="#000000" />
      <path d="M4.45455 10.2727H10.8182C11.0692 10.2727 11.2727 10.4762 11.2727 10.7273V11.1818C11.2727 11.4329 11.0692 11.6364 10.8182 11.6364H4.45455C4.20351 11.6364 4 11.4329 4 11.1818V10.7273C4 10.4762 4.20351 10.2727 4.45455 10.2727Z" fill="#000000" />
    </svg>


  );
};

export default EditIcon;
