import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Spin, Modal } from 'antd'

import SetPasswordForm from '../../Components/Forms/SetPasswordForm'
import AuthActions from '../../Redux/AuthRedux'
import errorHandler from '../../Utils/errorHandler'

const SetPasswordPage = props => {
  const { error, fetching, result, modalType, location } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const history = useHistory();

  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);
  const username = searchParams.get('username');
  const userid = searchParams.get('userid');

  useEffect(() => {
    if (modalType) {
      setModalVisible(false);
    } else {
      setModalVisible(true);
    }
  }, [modalType])

  useEffect(() => {
    if (result) {
      history.push('/');
      props.setModalType('login');
    }
  }, [result])

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error])

  const onSubmit = data => {
    data.email = username;
    data.customerCode = userid;
    props.setPassword(data);
  }

  return (
    <Modal
      forceRender
      mask={false}
      centered={true}
      closable={false}
      width={360}
      wrapClassName="modal-wrap no-mask"
      className="modal wo-cancel"
      title="Set password"
      visible={modalVisible}
      okText="Submit"
      okButtonProps={{
        htmlType: 'submit',
        className: 'btn-primary',
        form: 'set-password-form',
        disabled: fetching
      }}
      destroyOnClose={true}
    >
      <Spin size="large" spinning={fetching}>
        <SetPasswordForm
          onSubmit={onSubmit}
        />
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    result: state.auth.setPasswordResult,
    modalType: state.auth.modalType,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPassword: (query) => dispatch(AuthActions.setPasswordRequest(query)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  setModalType: (modalType) => dispatch(AuthActions.setModalType(modalType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage)
