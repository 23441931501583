import React from 'react'
import { useHistory } from 'react-router-dom'

import './style.less'

const Footer = props => {
  const { isLightBg } = props;

  const history = useHistory();

  return (
    <div className={`footer${isLightBg ? ' light' : ''}`}>
      <div className="title">2022 weCreate DATA GmbH</div>
      <div className="nav">
        <span onClick={() => history.push('/imprint')}>imprint</span>
        <span onClick={() => history.push('/terms')}>terms</span>
        <span onClick={() => history.push('/privacy')}>data privacy</span>
      </div>
    </div >
  );
};

export default Footer;
