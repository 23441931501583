import { put } from 'redux-saga/effects'
import StartupActions from '../Redux/StartupRedux'
import { push } from 'connected-react-router'

// process STARTUP actions
export function* startup(action) {
  const pathname = document.location.pathname;
  const search = document.location.search;
  const res = pathname + (search ? search : '');

  yield put(push(res))
  yield put(StartupActions.startupSuccess())
}
