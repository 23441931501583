
import React from 'react';
import { Collapse } from 'antd';
import Helmet from 'react-helmet';

import AngleDownIcon from '../../Components/Icons/AngleDownIcon';
import AngleUpIcon from '../../Components/Icons/AngleUpIcon';

import './style.less';

const { Panel } = Collapse;

const FaqPage = () => {
  const faqData = [
    {
      header: 'What information is included in the export?',
      text: 'We provide data about the location, language, statistics, contact information, if this data is available in the social media profile. Please refer to our samples to get an idea about the data structure and if it fits for you.'
    },
    {
      header: 'Is this data up-to-date?',
      text: 'We try to give you the most recent data. The exported records are between one day and maximum four weeks old.'
    },
    {
      header: 'Do you also provide private data or data from private accounts?',
      text: 'No, all our data comes from publicly accessible sources.'
    },
    {
      header: 'Can I be sure, that all information is 100% accurate?',
      text: 'Some parts of the data include profile information that the owner of the account provided, so this data may be inaccurate. Other parts of the information come from algorithms, like detecting the lanugage, gender or location of the influencer or brand. In many cases, this information is quite accurate, but could also include errors. We recommend our data sample or export of a small data set to check if the data quality fits your needs.'
    },
    {
      header: 'I already have mails or usernames and would like to exclude them from the data. Is it possible?',
      text: 'Yes, just send us a text file via contact@weafy.com and include the usernames or mails and the data will be excluded from your exports.'
    },
    {
      header: 'I have questions or issues regarding your tool or my exported data.',
      text: 'Please contact us via contact@weafy.com and we get back to you as soon as possible.'
    },
    {
      header: 'How frequently is the influencer and creator data updated on Weafy?',
      text: 'Our data is updated regularly to ensure accuracy. For most platforms like Instagram and TikTok, the data is refreshed every week, ensuring you have access to the latest follower counts, profile changes, and other relevant metrics.'
    },
    {
      header: 'What kind of analytics can I expect from Weafy’s data exports?',
      text: 'Weafy provides comprehensive analytics including follower growth trends, engagement rates, audience demographics such as age and location, and content performance metrics. This helps you gauge the effectiveness of influencers and creators for your marketing campaigns.'
    },
    {
      header: 'Can Weafy help identify emerging influencers and creators on platforms like Instagram and TikTok?',
      text: 'Yes, our platform is designed to spot emerging talents by analyzing growth trends, engagement rates, and other key performance indicators. This helps brands connect with rising stars in their early growth stages.'
    },
    {
      header: 'Is it possible to customize data exports based on specific criteria like location, language, or niche?',
      text: 'Absolutely. Weafy allows you to filter and export data based on various criteria including location, language, niche, follower count, and more, enabling you to tailor your influencer marketing strategy to specific target audiences.'
    },
    {
      header: 'Does Weafy provide support for analyzing the data?',
      text: 'Yes, our team offers support for data analysis. We can help you understand the metrics and provide insights on how to effectively use the data for your marketing strategies. Feel free to reach out via contact@weafy.com for any assistance.'
    },
    {
      header: 'What makes Weafy’s data export service stand out from other similar services?',
      text: 'Weafy stands out due to our real-time data updates, comprehensive analytics, user-friendly interface, and personalized customer support. Our focus on accuracy and usability makes us a preferred choice for businesses looking to leverage influencer marketing.'
    },
    {
      header: 'How does Weafy ensure the privacy and security of the data?',
      text: 'We are committed to data privacy and security. We comply with all relevant data protection laws and regulations, and our platform uses advanced security measures to protect the data. We only collect data from publicly accessible sources and respect user privacy.'
    },
    {
      header: 'Can Weafy’s exports be integrated with other marketing tools?',
      text: 'Yes, our data exports are designed in a versatile format that can be easily integrated with various marketing tools and platforms. This allows for seamless incorporation of influencer data into your existing marketing workflows and systems.'
    },
    {
      header: 'What is influencer data exporting, and why is it important?',
      text: 'Influencer data exporting involves collecting and downloading key information about social media influencers, such as contact details, follower count, engagement rates, and demographics. This is important for businesses to effectively identify, analyze, and engage with potential influencers for marketing campaigns.'
    },
    {
      header: 'What types of data can be exported about an influencer?',
      text: 'Typically, you can export an influencer’s profile details, contact information, audience demographics, engagement statistics, historical data on content performance, and any other metrics that are crucial for evaluating their influence and fit for your brand.'
    },
    {
      header: 'How can exported influencer data be used for marketing strategies?',
      text: 'Exported influencer data can be used to identify influencers who align with your brand values, analyze audience engagement, tailor marketing campaigns to specific demographics, track campaign performance, and optimize future influencer collaborations.'
    },
    {
      header: 'What should I look for when exporting data from influencer profiles?',
      text: 'Look for data that gives insights into the influencer’s audience quality, engagement rate, content style, consistency, growth trends, and relevance to your target market. This helps in making informed decisions for your influencer marketing strategies.'
    },
    {
      header: 'How can I ensure the accuracy of exported influencer data?',
      text: 'Ensure accuracy by using reputable data exporting tools or services that frequently update their data and cross-reference information from multiple sources. Regularly updating your data set is also crucial for maintaining its accuracy and relevance.'
    },
    {
      header: 'Is it legal to export and use influencer data?',
      text: 'Yes, it is legal as long as you comply with data privacy laws and regulations. Ensure that the data is collected and used ethically, respecting the privacy of influencers. Always use data obtained from publicly accessible sources and for legitimate business purposes.'
    },
    {
      header: 'Can I export influencer data from any social media platform?',
      text: 'This depends on the platform and the tools you are using. Most data exporting tools support popular platforms like Instagram and TikTok, but always check the capabilities and limitations of your chosen tool and ensure compliance with the platform’s terms of service.'
    },  
    {
      header: 'What is influencer marketing and how does it work?',
      text: 'Influencer marketing involves partnering with influencers and content creators on social media platforms to promote products, services, or brands. It works by leveraging the influencer’s audience and credibility to reach a wider and more engaged audience, often leading to increased brand awareness and sales.'
    },
    {
      header: 'How do I choose the right social media platform for influencer marketing?',
      text: 'Choosing the right platform depends on your target audience and marketing goals. Instagram and TikTok are great for visual content and younger demographics, while LinkedIn is more suited for B2B marketing. Analyzing where your target audience is most active is key to selecting the right platform.'
    },
    {
      header: 'What are the key metrics to consider in influencer marketing?',
      text: 'Key metrics include engagement rate (likes, comments, shares), reach (number of unique viewers), follower growth over time, and the quality of content. These metrics help assess the influencer’s ability to engage audiences and align with your brand values.'
    },
    {
      header: 'How can I measure the ROI of influencer marketing campaigns?',
      text: 'ROI can be measured by tracking metrics such as conversion rates, website traffic from influencer links, increase in brand mentions, sales figures, and audience engagement rates. Using trackable links and promo codes can also help in quantifying the impact.'
    },
    {
      header: 'Can small businesses benefit from influencer marketing?',
      text: 'Absolutely. Small businesses can benefit significantly from influencer marketing by partnering with micro-influencers who have smaller but highly engaged audiences. This can be a cost-effective strategy to reach target customers and build brand credibility.'
    },
    {
      header: 'What are the best practices for collaborating with influencers?',
      text: 'Best practices include clearly communicating your campaign goals, allowing creative freedom, selecting influencers whose audience aligns with your target market, establishing transparent compensation terms, and continuously monitoring and optimizing campaign performance.'
    },
    {
      header: 'How important is the authenticity of influencers in marketing campaigns?',
      text: 'Authenticity is crucial in influencer marketing. Influencers who genuinely resonate with your brand and convey honest opinions about your products tend to build more trust and engagement with their audience, leading to more effective marketing outcomes.'
    },
    {
      header: 'How can I ensure compliance with legal guidelines in influencer marketing?',
      text: 'Ensure compliance by being transparent about partnerships, using clear disclosure hashtags (like #ad or #sponsored), respecting copyright laws, and adhering to the advertising standards and guidelines set by regulatory bodies in your region.'
    }
  
  
  ];

  const faqs = faqData.map((faq, index) => (
    <Panel className="site-collapse-custom-panel" header={faq.header} key={index}>
      <p>{faq.text}</p>
    </Panel>
  ));

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqData.map(faq => ({
      "@type": "Question",
      "name": faq.header,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.text
      }
    }))
  };

  return (
    <div className="faq-page">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        expandIcon={({ isActive }) => isActive ? <AngleUpIcon /> : <AngleDownIcon />}
      >
        {faqs}
      </Collapse>
    </div>
  );
};

export default FaqPage;
