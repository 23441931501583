import { call, put, delay, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import AuthActions, { AuthSelectors } from '../Redux/AuthRedux'

export function* signIn(authApi, { query }) {
  try {
    const response = yield call(authApi.signIn, query)
    if (response && response.ok) {
      const redirectPath = yield select(AuthSelectors.getRedirectPath);
      yield delay(500)
      yield put(AuthActions.signInSuccess(response.data))
      if (redirectPath) {
        yield put(push(redirectPath))
      }
      yield put(AuthActions.setModalType(null))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(response.data))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.authErrorReset())
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* signUp(authApi, { query }) {
  try {
    const response = yield call(authApi.signUp, query)
    if (response && response.ok) {
      if (response.data.error) {
        yield put(AuthActions.signInFailure(response.data.error))
        yield put(AuthActions.authErrorReset())
      } else {
        yield delay(500)
        yield put(AuthActions.signUpSuccess(response.data))
        yield put(AuthActions.setModalType(null))
      }
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.authErrorReset())
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* forgotPassword(authApi, { query }) {
  try {
    const response = yield call(authApi.forgotPassword, query)
    if (response && response.ok) {
      yield delay(500)
      yield put(AuthActions.forgotPasswordSuccess(response.data))
      yield put(AuthActions.resultsReset())
      yield put(AuthActions.setModalType(null))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.authErrorReset())
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* setPassword(authApi, { query }) {
  try {
    const response = yield call(authApi.setPassword, query)
    if (response && response.ok) {
      yield delay(500)
      yield put(AuthActions.setPasswordSuccess(response.data))
      yield put(AuthActions.resultsReset())
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(null))
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
    }
  } catch (e) {
    yield put(AuthActions.authErrorReset())
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* getCustomerData(authApi) {
  try {
    const response = yield call(authApi.getCustomerData)
    if (response && response.ok) {
      yield delay(500)
      yield put(AuthActions.customerDataSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(AuthActions.signInFailure(response.data))
          yield* logout()
        } else if (response.status === 500) {
          yield put(AuthActions.signInFailure("Server response error"))
        } else {
          yield put(AuthActions.signInFailure(response.data))
        }
      } else {
        yield put(AuthActions.signInFailure(response?.originalError?.message || "INTERNET DISCONNECTED"))
      }
      yield put(AuthActions.authErrorReset())
      yield put(push('/'))
    }
  } catch (e) {
    yield put(AuthActions.authErrorReset())
    yield put(AuthActions.signInFailure('Unknown error: ' + e))
  }
}

export function* logout() {
  yield delay(500)
  yield put(AuthActions.logoutSuccess())
  // Remove cookies from browser (working only for production mode)
  if (document.cookie) {
    document.cookie.split(";").forEach(c => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
}
