import React from 'react'

import './style.less'

const ImprintPage = () => {
  return (
    <div className="terms-page">
      <div className="date">Last Updated: 06.07.2022</div>
      <h1>IMPRINT</h1>
      <div className="text">

      <div>
        <p>Information in accordance with §5 of the E-Commerce Act, §14 of the Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure requirements under §25 of the Media Act.</p>
        <p>weCreate Data GmbH<br />Obere Augartenstrasse 2/24,<br />1020 Wien,<br />Austria</p>
        <p>Object of the company: Marketing<br />VAT-Number: ATU73053839<br />Corporate register number: 482162m<br />Corporate register court: Wien<br />Company location: Wien</p>
        <p>Phone: 06603819240<br />Email: johannes@wecreate.media</p>
        <p>Member of: WKO<br />Laws re. professions: Gewerbeordnung: www.ris.bka.gv.at</p>
        <p>Supervisory/Trade authority: Magistrat der Stadt Wien<br />Job title: Marketing<br />Awarding country: Österreich</p>
        <p>Chief executive<br />Johannes Ruisinger</p>
        <p>Ownership structure<br />Gesellschafter: Hashtag Deep 24/7 67,33%, Etruvian 32,67%</p>
        <p>Source: Created with the Impressum Generator by AdSimple® Linkbuilding in cooperation with schoenheitsmagazin.at</p>
      </div>

      </div>
    </div>
  );
};

export default ImprintPage;
