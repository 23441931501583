// a library to wrap and simplify api calls
import apisauce from 'apisauce'

import utils from '../Utils/utils'

// our 'constructor'
const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {

  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    withCredentials: true,

    // here are some default headers
    headers: {
      'Content-Type': 'application/json'
    },
    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const serialize = utils.serialize;

  const signIn = query => {
    return api.post('/login/?' + serialize(query))
  }

  const signUp = query => {
    return api.post('/signup/?' + serialize(query))
  }

  const forgotPassword = query => {
    return api.post('/forgetPassword/?' + serialize(query))
  }

  const setPassword = query => {
    return api.post('/setPassword/?' + serialize(query))
  }

  const getCustomerData = () => {
    // Only for development mode
    let query = {};
    if (process.env.REACT_APP_CUSTOMER_CODE) {
      query.dev_cxustomer_code = process.env.REACT_APP_CUSTOMER_CODE;
    }
    return api.get('/getCustomerData/?' + serialize(query))
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //

  return {
    // a list of the API functions from step 2
    signIn,
    signUp,
    forgotPassword,
    setPassword,
    getCustomerData
  }
}

// let's return back our create method as the default.
export default create();
