import React from 'react'
import {
  Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom'
import { history } from './Redux'
import { Layout } from 'antd'

import MainPage from './Containers/MainPage'
import RootPage from './Containers/RootPage'
import ExportsPage from './Containers/ExportsPage'
import FaqPage from './Containers/FaqPage'
import BlogsPage from './Containers/BlogsPage'
import BlogPage from './Containers/BlogPage'
import TermsPage from './Containers/TermsPage'
import PrivacyPage from './Containers/PrivacyPage'
import ImprintPage from './Containers/ImprintPage'


import SetPasswordPage from './Containers/SetPasswordPage'
import NotFoundPage from './Containers/NotFoundPage'

function Routes(props) {
  const location = useLocation();

  const isLightBg = location.pathname.includes('/blog/');

  return (
    <Router history={history}>
      <Layout className={`main-layout${isLightBg ? ' light' : ''}`}>
        <MainPage isLightBg={isLightBg}>
          <Switch>
            <Route exact path="/" component={RootPage} />
            <Route exact path="/exports" component={ExportsPage} />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/blogs" component={BlogsPage} />
            <Route exact path="/blog/:id" component={BlogPage} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/imprint" component={ImprintPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route path="/setPassword" component={SetPasswordPage} />
            <Route path="/*" component={NotFoundPage} />
          </Switch>
        </MainPage>
      </Layout>
    </Router>
  )
}

export default Routes
