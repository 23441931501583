"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const defaultGetTokenErrorMessage = (_, tokenMeta) => {
    const { error } = tokenMeta;
    if (error === null) {
        return null;
    }
    if (typeof error === 'undefined' ||
        typeof error === 'string') {
        return error;
    }
    throw new TypeError('"onGetTokenErrorMessage" is required when "ErrorType" not "string"');
};
exports.default = defaultGetTokenErrorMessage;
