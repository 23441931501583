const data = [
  {
    value: "Germany",
    label: "🇩🇪 Germany",
    children: [
      "Berlin",
      "Hamburg",
      "Munich",
      "Cologne",
      "Frankfurt am Main",
      "Essen",
      "Stuttgart",
      "Dortmund",
      "Dusseldorf",
      "Bremen",
      "Hanover",
      "Nuremberg",
      "Leipzig",
      "Duisburg",
      "Dresden",
      "Bochum",
      "Wuppertal",
      "Bielefeld",
      "Bonn",
      "Mannheim",
      "Karlsruhe",
      "Wiesbaden",
      "Münster",
      "Gelsenkirchen",
      "Aachen",
      "Mönchengladbach",
      "Augsburg",
      "Chemnitz",
      "Brunswick",
      "Krefeld",
      "Kiel",
      "Magdeburg",
      "Oberhausen",
      "Freiburg",
      "Lübeck",
      "Erfurt",
      "Hagen",
      "Rostock",
      "Kassel",
      "Mainz",
      "Saarbrücken",
      "Herne",
      "Osnabrück",
      "Solingen",
      "Ludwigshafen am Rhein",
      "Leverkusen",
      "Oldenburg",
      "Neuss",
      "Potsdam",
      "Heidelberg",
      "Paderborn",
      "Darmstadt",
      "Wurzburg",
      "Regensburg",
      "Wolfsburg",
      "Recklinghausen",
      "Göttingen",
      "Heilbronn",
      "Ingolstadt",
      "Ulm",
      "Bottrop",
      "Pforzheim",
      "Bremerhaven",
      "Remscheid",
      "Reutlingen",
      "Koblenz",
      "Siegen",
      "Bergisch Gladbach",
      "Jena",
      "Hildesheim",
      "Erlangen",
      "Trier",
      "Zwickau",
      "Kaiserslautern",
      "Schwerin",
      "Gütersloh",
      "Düren",
      "Esslingen am Neckar",
      "Hanau",
      "Ludwigsburg",
      "Flensburg",
      "Cottbus",
      "Wilhelmshaven",
      "Tübingen",
      "Minden",
      "Villingen-Schwenningen",
      "Konstanz",
      "Worms",
      "Lüdenscheid",
      "Marburg",
      "Neumünster",
      "Arnsberg",
      "Viersen",
      "Delmenhorst",
      "Bayreuth",
      "Giessen",
      "Detmold",
      "Lüneburg",
      "Celle",
      "Bamberg",
      "Aschaffenburg",
      "Neubrandenburg",
      "Aalen",
      "Neuwied",
      "Unna",
      "Plauen",
      "Herford",
      "Weimar",
      "Fulda",
      "Wesel",
      "Landshut",
      "Rosenheim",
      "Offenburg",
      "Stralsund",
      "Hamelin",
      "Friedrichshafen",
      "Göppingen",
      "Görlitz",
      "Euskirchen",
      "Wolfenbüttel",
      "Schweinfurt",
      "Neustadt",
      "Gummersbach",
      "Waiblingen",
      "Nordhorn",
      "Greifswald",
      "Cuxhaven",
      "Wetzlar",
      "Bad Homburg",
      "Emden",
      "Neu-Ulm",
      "Passau",
      "Langenhagen",
      "Spires",
      "Heidenheim",
      "Neunkirchen",
      "Hof",
      "Kleve",
      "Ravensburg",
      "Soest",
      "Rastatt",
      "Frankenthal",
      "Loerrach",
      "Gotha",
      "Böblingen",
      "Stade",
      "Hennef",
      "Wismar",
      "Amberg",
      "Straubing",
      "Nordhausen",
      "Freiberg",
      "Pirmasens",
      "Goslar",
      "Suhl",
      "Rodgau",
      "Bad Kreuznach",
      "Freising",
      "Kaufbeuren",
      "Nettetal",
      "Eberswalde",
      "Bautzen",
      "Coburg",
      "Heinsberg",
      "Borken",
      "Oranienburg",
      "Pinneberg",
      "Pirna",
      "Aurich",
      "Dachau",
      "Halberstadt",
      "Neumarkt in der Oberpfalz",
      "Siegburg",
      "Warendorf",
      "Altenburg",
      "Schwabach",
      "Saarlouis",
      "Mühlhausen",
      "Hofheim am Taunus",
      "Stendal",
      "Coesfeld",
      "Zweibrücken",
      "Tuttlingen",
      "Merseburg",
      "Steinfurt",
      "Limburg an der Lahn",
      "Fürstenfeldbruck",
      "Erding",
      "Dietzenbach",
      "Höxter",
      "Nienburg",
      "Biberach an der Riss",
      "Meschede",
      "Bernburg",
      "Eisenhüttenstadt",
      "Neuruppin",
      "Ansbach",
      "Güstrow",
      "Merzig",
      "Deggendorf",
      "Bad Hersfeld",
      "Forchheim",
      "Schwelm",
      "Naumburg",
      "Senftenberg",
      "Köthen",
      "Meissen",
      "Neuburg an der Donau",
      "Rendsburg",
      "Schwandorf in Bayern",
      "Saalfeld",
      "Kulmbach",
      "Friedberg",
      "Rathenow",
      "Landsberg am Lech",
      "Verden an der Aller",
      "Sankt Wendel",
      "Garmisch-Partenkirchen",
      "Zirndorf",
      "Olpe",
      "Arnstadt",
      "Helmstedt",
      "Heppenheim an der Bergstrasse",
      "Roth"
    ]
  },
  {
    value: "Austria",
    label: "🇦🇹 Austria",
    children: [
      "Vienna",
      "Graz",
      "Linz",
      "Salzburg",
      "Innsbruck",
      "Klagenfurt",
      "Villach",
      "Wels",
      "St. Pölten",
      "Dornbirn",
      "Steyr",
      "Wiener Neustadt",
      "Feldkirch",
      "Bregenz",
      "Baden bei Wien",
      "Klosterneuburg",
      "Leoben",
      "Krems",
      "Amstetten",
      "Leonding",
      "Kapfenberg",
      "Mödling",
      "Lustenau",
      "Hallein",
      "Spittal an der Drau",
      "Saalfelden am Steinernen Meer",
      "Kufstein",
      "Schwechat",
      "Ternitz",
      "Stockerau",
      "Feldkirchen in Kärnten",
      "Hohenems",
      "Perchtoldsdorf",
      "Telfs",
      "Bludenz",
      "Bruck an der Mur",
      "Sankt Veit an der Glan",
      "Knittelfeld",
      "Schwaz",
      "Hall in Tirol",
      "Lienz",
      "Eisenstadt",
      "Vöcklabruck",
      "Waidhofen an der Ybbs",
      "Marchtrenk",
      "Ried im Innkreis",
      "Korneuburg",
      "Rankweil",
      "Völkermarkt",
      "Wörgl",
      "Bad Vöslau",
      "Hollabrunn",
      "St Johann im Pongau",
      "Mistelbach",
      "Köflach",
      "Götzis",
      "Sankt Andrä",
      "Bischofshofen",
      "Zell am See",
      "Judenburg",
      "Voitsberg",
      "Seekirchen am Wallersee",
      "Ebreichsdorf",
      "Mürzzuschlag",
      "Imst",
      "Weiz",
      "Gerasdorf bei Wien",
      "Attnang-Puchheim",
      "Kitzbühel",
      "Groß-Enzersdorf",
      "Wiener Neudorf",
      "Engerwitzdorf",
      "Finkenstein am Faaker See",
      "Purkersdorf",
      "Trofaiach",
      "Fohnsdorf",
      "Wolfurt",
      "Maria Enzersdorf",
      "Deutschlandsberg",
      "Herzogenburg",
      "Langenzersdorf",
      "Zeltweg",
      "Ferlach",
      "Perg",
      "Strasshof an der Nordbahn",
      "Wattens",
      "Bruck an der Leitha",
      "Kottingbrunn",
      "Deutsch-Wagram",
      "Strasswalchen",
      "Langenlois",
      "Sieghartskirchen",
      "Leibnitz, Styria",
      "Bad Hofgastein",
      "Arnoldstein",
      "Grödig",
      "Liezen",
      "Oberwart",
      "Jenbach",
      "Gratkorn",
      "Kuchl",
      "Hartberg",
      "Frohnleiten",
      "Horn",
      "Mattersburg",
      "Hörbranz",
      "Paternion",
      "Gallneukirchen",
      "Gloggnitz",
      "Tamsweg",
      "Pottendorf",
      "Eberndorf",
      "Laa an der Thaya",
      "Pressbaum",
      "Altach",
      "Fürstenfeld",
      "Neusiedl am See",
      "Bad Gastein",
      "Nenzing",
      "Timelkam",
      "Reutte",
      "Waidhofen an der Thaya",
      "Abtenau",
      "Traismauer",
      "Schrems",
      "Kindberg",
      "Mittersill",
      "Ybbs an der Donau",
      "Zistersdorf",
      "Poysdorf",
      "Rottenmann",
      "Himberg",
      "Thalgau",
      "Hörsching",
      "Feldkirchen an der Donau",
      "Melk",
      "Pinkafeld",
      "Gleisdorf",
      "Krieglach",
      "Bad Aussee",
      "Lochau",
      "Ebbs",
      "Matrei in Osttirol",
      "Lenzing",
      "Radstadt",
      "Schärding",
      "Feldbach",
      "Vöcklamarkt",
      "Altheim",
      "Grieskirchen",
      "Schladming",
      "Bruck an der Großglocknerstraße",
      "Nüziders",
      "Scheibbs",
      "Heidenreichstein",
      "Jennersdorf",
      "Neudörfl",
      "Fieberbrunn",
      "Anif",
      "Retz",
      "Hinterbrühl",
      "Parndorf",
      "Bad Leonfelden",
      "Mayrhofen",
      "St. Gilgen",
      "Westendorf",
      "Frankenmarkt",
      "Strobl",
      "Loosdorf",
      "Schruns",
      "Solden",
      "Mondsee",
      "Aigen im Mühlkreis",
      "Bürs",
      "Seefeld in Tirol",
      "Ulrichsberg",
      "Kaprun",
      "Taxenbach",
      "Bad Erlach",
      "Brixen im Thale",
      "Reichenau an der Rax",
      "Alpbach",
      "Pernegg an der Mur",
      "Bad Kreuzen",
      "Großebersdorf",
      "Oetz",
      "Garsten",
      "Ischgl",
      "Murau",
      "Leutasch",
      "Waldzell",
      "Kittsee",
      "Enzenreith",
      "Hall bei Admont",
      "Bad Blumau",
      "Scheifling",
      "Mariazell",
      "Nauders",
      "Breitenwang",
      "Peuerbach",
      "Telfes im Stubai",
      "Hippach",
      "Unterbergla",
      "Zillingdorf",
      "Serfaus",
      "Heiligenblut am Großglockner",
      "Tannheim",
      "Schönberg im Stubaital",
      "Hochwolkersdorf",
      "Roitham",
      "Neuhaus am Klausenbach",
      "Semmering",
      "Pichl bei Wels"
    ]
  },
  {
    value: "Switzerland",
    label: "🇨🇭 Switzerland",
    children: [
      "Zurich",
      "Geneva",
      "Basel",
      "Bern",
      "Lausanne",
      "Winterthur",
      "St. Gallen",
      "Lucerne",
      "Biel/Bienne",
      "Thun",
      "La Chaux-de-Fonds",
      "Schaffhausen",
      "Fribourg",
      "Chur",
      "Neuchâtel",
      "Sion",
      "Lugano",
      "Yverdon-les-Bains",
      "Uster",
      "Frauenfeld",
      "Renens",
      "Kreuzlingen",
      "Nyon",
      "Bellinzona",
      "Olten",
      "Horgen",
      "Vevey",
      "Sierre",
      "Aarau",
      "Herisau",
      "Monthey",
      "Solothurn",
      "Martigny-Ville",
      "Locarno",
      "Schwyz",
      "Morges"
    ]
  },
  {
    value: "United States of America",
    label: "🇺🇸 United States of America",
    children: ["Aberdeen", "Abilene", "Akron", "Albany", "Albuquerque", "Alexandria", "Allentown", "Amarillo", "Anaheim", "Anchorage", "Ann Arbor", 
    "Antioch", "Apple Valley", "Appleton", "Arlington", "Arvada", "Asheville", "Athens", "Atlanta", "Atlantic City", "Augusta", "Aurora", "Austin", 
    "Bakersfield", "Baltimore", "Barnstable", "Baton Rouge", "Beaumont", "Bel Air", "Bellevue", "Berkeley", "Bethlehem", "Billings", "Birmingham", 
    "Bloomington", "Boise", "Boise City", "Bonita Springs", "Boston", "Boulder", "Bradenton", "Bremerton", "Bridgeport", "Brighton", "Brownsville", 
    "Bryan", "Buffalo", "Burbank", "Burlington", "Cambridge", "Canton", "Cape Coral", "Carrollton", "Cary", "Cathedral City", "Cedar Rapids", "Champaign", 
    "Chandler", "Charleston", "Charlotte", "Chattanooga", "Chesapeake", "Chicago", "Chula Vista", "Cincinnati", "Clarke County", "Clarksville", "Clearwater", 
    "Cleveland", "College Station", "Colorado Springs", "Columbia", "Columbus", "Concord", "Coral Springs", "Corona", "Corpus Christi", "Costa Mesa", "Dallas", 
    "Daly City", "Danbury", "Davenport", "Davidson County", "Dayton", "Daytona Beach", "Deltona", "Denton", "Denver", "Des Moines", "Detroit", "Downey", "Duluth", 
    "Durham", "El Monte", "El Paso", "Elizabeth", "Elk Grove", "Elkhart", "Erie", "Escondido", "Eugene", "Evansville", "Fairfield", "Fargo", "Fayetteville", 
    "Fitchburg", "Flint", "Fontana", "Fort Collins", "Fort Lauderdale", "Fort Smith", "Fort Walton Beach", "Fort Wayne", "Fort Worth", "Frederick", "Fremont", 
    "Fresno", "Fullerton", "Gainesville", "Garden Grove", "Garland", "Gastonia", "Gilbert", "Glendale", "Grand Prairie", "Grand Rapids", "Grayslake", "Green Bay", 
    "GreenBay", "Greensboro", "Greenville", "Gulfport-Biloxi", "Hagerstown", "Hampton", "Harlingen", "Harrisburg", "Hartford", "Havre de Grace", "Hayward", "Hemet", 
    "Henderson", "Hesperia", "Hialeah", "Hickory", "High Point", "Hollywood", "Honolulu", "Houma", "Houston", "Howell", "Huntington", "Huntington Beach", "Huntsville", 
    "Independence", "Indianapolis", "Inglewood", "Irvine", "Irving", "Jackson", "Jacksonville", "Jefferson", "Jersey City", "Johnson City", "Joliet", "Kailua", 
    "Kalamazoo", "Kaneohe", "Kansas City", "Kennewick", "Kenosha", "Killeen", "Kissimmee", "Knoxville", "Lacey", "Lafayette", "Lake Charles", "Lakeland", "Lakewood", 
    "Lancaster", "Lansing", "Laredo", "Las Cruces", "Las Vegas", "Layton", "Leominster", "Lewisville", "Lexington", "Lincoln", "Little Rock", "Long Beach", "Lorain", 
    "Los Angeles", "Louisville", "Lowell", "Lubbock", "Macon", "Madison", "Manchester", "Marina", "Marysville", "McAllen", "McHenry", "Medford", "Melbourne", "Memphis", 
    "Merced", "Mesa", "Mesquite", "Miami", "Milwaukee", "Minneapolis", "Miramar", "Mission Viejo", "Mobile", "Modesto", "Monroe", "Monterey", "Montgomery", 
    "Moreno Valley", "Murfreesboro", "Murrieta", "Muskegon", "Myrtle Beach", "Naperville", "Naples", "Nashua", "Nashville", "New Bedford", "New Haven", "New London",
     "New Orleans", "New York", "New York City", "Newark", "Newburgh", "Newport News", "Norfolk", "Normal", "Norman", "North Charleston", "North Las Vegas", 
     "North Port", "Norwalk", "Norwich", "Oakland", "Ocala", "Oceanside", "Odessa", "Ogden", "Oklahoma City", "Olathe", "Olympia", "Omaha", "Ontario", "Orange", 
     "Orem", "Orlando", "Overland Park", "Oxnard", "Palm Bay", "Palm Springs", "Palmdale", "Panama City", "Pasadena", "Paterson", "Pembroke Pines", "Pensacola", 
     "Peoria", "Philadelphia", "Phoenix", "Pittsburgh", "Plano", "Pomona", "Pompano Beach", "Port Arthur", "Port Orange", "Port Saint Lucie", "Port St. Lucie", 
     "Portland", "Portsmouth", "Poughkeepsie", "Providence", "Provo", "Pueblo", "Punta Gorda", "Racine", "Raleigh", "Rancho Cucamonga", "Reading", "Redding", 
     "Reno", "Richland", "Richmond", "Richmond County", "Riverside", "Roanoke", "Rochester", "Rockford", "Roseville", "Round Lake Beach", "Sacramento", "Saginaw", 
     "Saint Louis", "Saint Paul", "Saint Petersburg", "Salem", "Salinas", "Salt Lake City", "San Antonio", "San Bernardino", "San Buenaventura", "San Diego", 
     "San Francisco", "San Jose", "Santa Ana", "Santa Barbara", "Santa Clara", "Santa Clarita", "Santa Cruz", "Santa Maria", "Santa Rosa", "Sarasota", "Savannah", 
     "Scottsdale", "Scranton", "Seaside", "Seattle", "Sebastian", "Shreveport", "Simi Valley", "Sioux City", "Sioux Falls", "South Bend", "South Lyon", "Spartanburg", 
     "Spokane", "Springdale", "Springfield", "St. Louis", "St. Paul", "St. Petersburg", "Stamford", "Sterling Heights", "Stockton", "Sunnyvale", "Syracuse", "Tacoma", 
     "Tallahassee", "Tampa", "Temecula", "Tempe", "Thornton", "Thousand Oaks", "Toledo", "Topeka", "Torrance", "Trenton", "Tucson", "Tulsa", "Tuscaloosa", "Tyler", 
     "Utica", "Vallejo", "Vancouver", "Vero Beach", "Victorville", "Virginia Beach", "Visalia", "Waco", "Warren", "Washington", "Waterbury", "Waterloo", "West Covina", 
     "West Valley City", "Westminster", "Wichita", "Wilmington", "Winston", "Winter Haven", "Worcester", "Yakima", "Yonkers", "York", "Youngstown"]
  },
  {
    value: "Albania",
    label: "🇦🇱 Albania",
    children: [
      "Elbasan",
      "Vlore",
      "Tirana"
    ]
  },
  {
    value: "Algeria",
    label: "🇩🇿 Algeria",
    children: [
      "Algiers",
      "Sidi bel Abbes",
      "Skikda",
      "Medea",
      "Bechar",
      "Oran",
      "Constantine",
      "Chlef",
      "Arak"
    ]
  },
  {
    value: "Argentina",
    label: "🇦🇷 Argentina",
    children: [
      "Cordoba",
      "Buenos Aires",
      "Rosario",
      "San Miguel de Tucuman",
      "La Plata",
      "Resistencia",
      "Posadas",
      "Santiago del Estero",
      "Neuquen",
      "Rio Cuarto",
      "Concordia",
      "San Nicolas",
      "Santa Rosa",
      "Trelew",
      "Reconquista",
      "Tandil",
      "Mendoza",
      "San Rafael",
      "Campana",
      "Goya",
      "Lujan",
      "San Ramon de la Nueva Oran",
      "Junin",
      "Tartagal",
      "Punta Alta",
      "Gualeguaychu",
      "Mar del Plata",
      "Ushuaia"
    ]
  },
  {
    value: "Armenia",
    label: "🇦🇲 Armenia",
    children: [
      "Yerevan",
      "Gyumri"
    ]
  },
  {
    value: "Australia",
    label: "🇦🇺 Australia",
    children: [
      "Melbourne",
      "Brisbane",
      "Newcastle",
      "Gold Coast",
      "Sydney",
      "Wollongong",
      "Perth",
      "Adelaide",
      "Mackay",
      "Launceston",
      "Nowra",
      "Rockhampton",
      "Sunshine Coast"
    ]
  },
  {
    value: "Bahrain",
    label: "🇧🇭 Bahrain",
    children: []
  },
  {
    value: "Belarus",
    label: "🇧🇾 Belarus",
    children: [
      "Homyel",
      "Vitsyebsk",
      "Babruysk",
      "Minsk",
      "Baranavichy",
      "Orsha",
      "Barysaw",
      "Pinsk"
    ]
  },
  {
    value: "Belgium",
    label: "🇧🇪 Belgium",
    children: [
      "Antwerpen",
      "Gent",
      "Charleroi",
      "Brussels",
      "Namur"
    ]
  },
  {
    value: "Bolivia",
    label: "🇧🇴 Bolivia",
    children: [
      "Santa Cruz",
      "La Paz",
      "Oruro",
      "Cochabamba",
      "Trinidad"
    ]
  },
  {
    value: "Bosnia and Herzegovina",
    label: "🇧🇦 Bosnia and Herzegovina",
    children: [
      "Mostar",
      "Prijedor",
      "Sarajevo",
    ]
  },
  {
    value: "Brazil",
    label: "🇧🇷 Brazil",
    children: [
      "Sao Paulo",
      "Rio de Janeiro",
      "Brasilia",
      "Salvador",
      "Fortaleza",
      "Porto Alegre",
      "Belem",
      "Goiania",
      "Maceio",
      "Natal",
      "Joao Pessoa",
      "Teresina",
      "Vila Velha",
      "Joinville",
      "Sao Jose dos Campos",
      "Cuiaba",
      "Aracaju",
      "Sorocaba",
      "Sao Luis",
      "Juiz de Fora",
      "Feira de Santana",
      "Macapa",
      "Jundiai",
      "Belo Horizonte",
      "Campina Grande",
      "Caxias do Sul",
      "Sao Jose do Rio Preto",
      "Taubate",
      "Maringa",
      "Bauru",
      "Porto Velho",
      "Franca",
      "Anapolis",
      "Vitoria da Conquista",
      "Ponta Grossa",
      "Recife",
      "Santa Maria",
      "Caruaru",
      "Curitiba",
      "Petrolina",
      "Palmas",
      "Santarem",
      "Imperatriz",
      "Boa Vista",
      "Ilheus",
      "Campinas",
      "Marilia",
      "Criciuma",
      "Cachoeiro de Itapemirim",
      "Crato",
      "Manaus",
      "Sobral",
      "Dourados",
      "Jequie",
      "Jaragua do Sul",
      "Pocos de Caldas",
      "Castanhal",
      "Guarapuava",
      "Pouso Alegre",
      "Barbacena",
      "Vitoria",
      "Niteroi",
      "Uruguaiana",
      "Botucatu",
      "Bento Goncalves",
      "Arapongas",
      "Barreiras",
      "Patos",
      "Erechim",
      "Passos",
      "Nova Iguacu",
      "Duque de Caxias",
      "Brusque",
      "Araguari",
      "Muriae",
      "Araxa",
      "Corumba",
      "Campo Grande",
      "Sao Joao del Rei",
      "Jaboatao",
      "Santo Andre",
      "Olinda",
      "Itumbiara",
      "Tres Lagoas",
      "Sao Mateus",
      "Formosa",
      "Jaboticabal",
      "Nova Lima",
      "Santa Ines",
      "Goiana",
      "Bacabal",
      "Florianopolis",
      "Braganca",
      "Valenca",
      "Manacapuru",
      "Novo Hamburgo",
      "Telemaco Borba",
      "Catalao",
      "Serrinha",
      "Ribeirao Preto",
      "Coari",
      "Guajara-Miram",
      "Paracatu",
      "Timbauba",
      "Porto Uniao",
      "Londrina",
      "Uberlandia",
      "Sao Borja",
      "Tefe",
      "Irece",
      "Caratinga",
      "Vacaria",
      "Sao Jose dos Pinhais",
      "Breves",
      "Canoas",
      "Concordia",
      "Formiga",
      "Gurupi",
      "Camaqua",
      "Rio Negro",
      "Guaxupe",
      "Ararangua",
      "Campo Belo",
      "Sao Gabriel",
      "Barra do Garcas"
    ]
  },
  {
    value: "Bulgaria",
    label: "🇧🇬 Bulgaria",
    children: [
      "Sofia",
      "Plovdiv",
      "Stara Zagora",
      "Pleven",
      "Sliven",
      "Shumen",
      "Kyustendil"
    ]
  },
  {
    value: "Canada",
    label: " 🇨🇦 Canada",
    children: [
      "Toronto",
      "Ottawa",
      "Edmonton",
      "Kitchener",
      "Montréal",
      "Halifax",
      "Victoria",
      "Windsor",
      "Saskatoon",
      "Barrie",
      "Vancouver",
      "Sherbrooke",
      "St. John's",
      "Kelowna",
      "Kingston",
      "Calgary",
      "St.-Jerome",
      "Prince George",
      "Hamilton",
      "Winnipeg",
      "Quebec",
      "Cornwall",
      "North Bay",
      "Shawinigan",
      "Grand Prairie",
      "Joliette"
    ]
  },
  {
    value: "Colombia",
    label: "🇨🇴 Columbia",
    children: [
      "Bogota",
      "Medellin",
      "Barranquilla",
      "Armenia",
      "Valledupar",
      "Sincelejo",
      "Tulua",
      "Tunja",
      "Girardot",
      "Sogamoso",
      "Riohacha",
      "Ipiales",
      "Cartagena",
      "Bucaramanga",
      "Cucuta",
      "El Carmen de Bolivar",
      "Soledad",
      "Pereira",
      "Chiquinquira",
      "Arauca",
      "Leticia",
      "Santa Marta",
      "Ibague"
    ]
  },
  {
    value: "Chile",
    label: "🇨🇱 Chile",
    children: [
      "Santiago",
      "Vina del Mar",
      "Rancagua",
      "Talca",
      "Arica",
      "Puerto Montt",
      "Coquimbo",
      "Calama",
      "Copiapo",
      "Curico",
      "San Antonio",
      "Coronel",
      "Quillota",
      "Concepcion",
      "San Felipe",
      "Valparaiso"
    ]
  },
  {
    value: "Costa Rica",
    label: "🇨🇷 Costa Rica",
    children: [
      "Alajuela",
      "San Jose"
    ]
  },
  {
    value: "Croatia",
    label: "🇭🇷 Croatia",
    children: [
      "Split",
      "Osijek",
      "Zagreb",
      "Zadar"
    ]
  },
  {
    value: "Cuba",
    label: "🇨🇺 Cuba",
    children: [
      "Havana",
      "Camaguey",
      "Holguin",
      "Guantanamo",
      "Cienfuegos",
      "Ciego de Avila",
      "Sancti Spiritus",
      "Santiago de Cuba",
      "Artemisa",
      "Sagua la Grande"
    ]
  },
  {
    value: "Cyprus",
    label: "🇨🇾  Cyprus",
    children: []
  },
  {
    value: "Czech Republic",
    label: "🇨🇿 Czech Republic",
    children: [
      "Prague",
      "Ostrava",
      "Zlin",
      "Liberec",
      "Pardubice",
      "Jihlava",

    ]
  },
  {
    value: "Denmark",
    label: "🇩🇰 Denmark",
    children: [
      "Aarhus",
      "Odense",
      "Aalborg",
      "Kobenhavn",
      "Roskilde"
    ]
  },
  {
    value: "Dominican Republic",
    label: "🇩🇴 Dominican Republic",
    children: [
      "Santiago",
      "Santo Domingo",
      "San Pedro de Macoris",
      "La Romana",
      "San Francisco de Macoris",
      "La Vega"
    ]
  },
  {
    value: "Ecuador",
    label: "🇪🇨 Ecuador",
    children: [
      "Guayaquil",
      "Machala",
      "Portoviejo",
      "Quito",
      "Esmeraldas",
      "Ibarra",
      "Latacunga",
      "Milagro"
    ]
  },
  {
    value: "Egypt",
    label: "🇪🇬 Egypt",
    children: [
      "Cairo",
      "Sohag",
      "Alexandria",
      "El Faiyum",
      "Aswan",
      "Qena",
      "El Giza",
      "Kom Ombo",
      "Mallawi",
      "Rashid",
      "Girga",
      "Bur Said",
      "El Mansura",
      "Ismailia",
      "Asyut",
      "Tanta"
    ]
  },
  {
    value: "Estonia",
    label: "🇪🇪 Estonia",
    children: [
      "Tallinn",
      "Tartu"
    ]
  },
  {
    value: "Finland",
    label: "🇫🇮 Finland",
    children: [
      "Helsinki",
      "Lappeenranta"
    ]
  },
  {
    value: "France",
    label: "🇫🇷 France",
    children: [
      "Paris",
      "Marseille",
      "Lyon",
      "Toulouse",
      "Strasbourg",
      "Rouen",
      "Rennes",
      "Tours",
      "Clermont-Ferrand",
      "Villeurbanne",
      "Le Mans",
      "Saint-Denis",
      "Aix-en-Provence",
      "Perpignan",
      "Metz",
      "Besançon",
      "Boulogne-Billancourt",
      "Orléans",
      "Mulhouse",
      "Argenteuil",
      "Montreuil",
      "Angers",
      "Nimes",
      "Caen",
      "Limoges",
      "Melun",
      "Amiens",
      "Poitier",
      "Pointe-a-Pitre",
      "Beziers",
      "Annecy",
      "La Rochelle",
      "Bourges",
      "Lille",
      "Grenoble",
      "Dijon",
      "Saint-Étienne",
      "Le Havre",
      "Reims",
      "Montpellier",
      "Nantes",
      "Toulon",
      "Nice",
      "Cayenne",
      "Roanne",
      "Arras",
      "St.-Brieuc",
      "Bordeaux",
      "Nevers",
      "Bastia",
      "Pau",
      "Brest",
      "Saint-Paul",
      "Nancy",
      "Tourcoing",
      "Roubaix",
      "Nanterre",
      "Vitry-sur-Seine",
      "Avignon",
      "Créteil",
      "Poitiers",
      "Dunkirk",
      "Aubervilliers",
      "Versailles",
      "Aulnay-sous-Bois",
      "Asnières-sur-Seine",
      "Colombes",
      "Saint-Pierre",
      "Courbevoie",
      "Fort-de-France",
      "Cherbourg-en-Cotentin",
      "Le Tampon",
      "Rueil-Malmaison",
      "Champigny-sur-Marne",
      "Saint-Maur-des-Fossés",
      "Calais",
      "Cannes",
      "Antibes",
      "Mamoudzou",
      "Drancy",
      "Ajaccio",
      "Saint-Nazaire",
      "Colmar",
      "Issy-les-Moulineaux",
      "Noisy-le-Grand",
      "Évry-Courcouronnes",
      "Vénissieux",
      "Cergy",
      "Bourges",
      "Levallois-Perret",
      "La Seyne-sur-Mer",
      "Pessac",
      "Valence",
      "Quimper",
      "Antony",
      "Troyes",
      "Clichy",
      "Corsica"
    ]
  },
  {
    value: "Greece",
    label: "🇫🇷 Greece",
    children: [
      "Athens",
      "Patra",
      "Volos",
      "Hania",
      "Kalamata",
      "Thessaloniki",
      "Rodos",
      "Seres",
      "Katerini",
      "Xanthi",
      "Alexandroupoli"
    ]
  },
  {
    value: "Guatemala",
    label: "🇬🇹 Guatemala",
    children: [
      "Escuintla",
      "Guatemala",
      "Coban",
      "Puerto Barrios",
      "Jutiapa"
    ]
  },
  {
    value: "Honduras",
    label: "🇭🇳 Honduras",
    children: [
      "San Pedro Sula",
      "Tegucigalpa",
      "Choluteca"
    ]
  },
  {
    value: "Hong Kong S.A.R.",
    label: "🇭🇰 Hong Kong",
    children: ["Hong Kong"]
  },
  {
    value: "Hungary",
    label: "🇭🇺 Hungary",
    children: [
      "Budapest",
      "Szekesfehervar",
      "Szolnok"
    ]
  },
  {
    value: "Iceland",
    label: "🇮🇸 Iceland",
    children: []
  },
  {
    value: "Indonesia",
    label: "🇮🇩 Indonesia",
    children: [
      "Jakarta",
      "Bandung",
      "Medan",
      "Bandjarmasin",
      "Pontianak",
      "Denpasar",
      "Manado",
      "Balikpapan",
      "Jambi",
      "Mataram",
      "Binjai",
      "Bengkulu",
      "Banda Aceh",
      "Pakalongan",
      "Surabaya",
      "Bekasi",
      "Tebingtinggi",
      "Padangsidempuan",
      "Salatiga",
      "Palembang",
      "Tarakan",
      "Semarang",
      "Ujungpandang",
      "Cilacap",
      "Pangkalpinang",
      "Tanjungkarang-Telubketung",
      "Bogor",
      "Padang",
      "Bandar Lampung",
      "Sampit",
      "Malang",
      "Maumere",
      "Pekanbaru",
      "Yogyakarta",
      "Surakarta",
      "Lahat",
      "Samarinda",
      "Ruteng",
      "Luwuk"
    ]
  },
  {
    value: "Iran",
    label: "🇮🇷 Iran",
    children: [
      "Tehran",
      "Mashhad",
      "Ahvaz",
      "Zahedan",
      "Rasht",
      "Yazd",
      "Bandar-e-Abbas",
      "Zanjan",
      "Khorramabad",
      "Sari",
      "Birjand",
      "Kashan",
      "Neyshabur",
      "Sabzewar",
      "Bojnurd",
      "Zabol",
      "Bandar-e Bushehr",
      "Isfahan",
      "Mahabad",
      "Saveh",
      "Karaj",
      "Masjed Soleyman",
      "Tabriz",
      "Quchan",
      "Shiraz",
      "Fasa",
      "Ahar",
      "Kermanshah",
      "Orumiyeh",
      "Kerman",
      "Khomeini Shahr",
      "Ardabil"
    ]
  },
  {
    value: "Iraq",
    label: "🇮🇶 Iraq",
    children: [
      "Kirkuk",
      "Baghdad",
      "Al Hillah",
      "Ad Diwaniyah",
      "Al Amarah",
      "Al Kut",
      "Baqubah",
      "Az Aubayr",
      "Mosul",
      "Ash Shatrah",
      "Zakho",
      "Basra",
      "Irbil",
      "As Sulaymaniyah",
      "Dahuk",
      "An Najaf",
      "Al Musayyib",
      "Karbala",
      "An Nasiriyah"
    ]
  },
  {
    value: "Ireland",
    label: "🇮🇪 Ireland",
    children: [
      "Dublin"
    ]
  },
  {
    value: "Israel",
    label: "🇮🇱 Israel",
    children: [
      "Tel Aviv-Yafo",
      "Nazareth",
      "Jerusalem",
      "Haifa"
    ]
  },
  {
    value: "Italy",
    label: "🇮🇹 Italy",
    children: [
      "Rome",
      "Milan",
      "Naples",
      "Turin",
      "Palermo",
      "Genoa",
      "Bologna",
      "Florence",
      "Catania",
      "Bari",
      "Venice",
      "Verona",
      "Messina",
      "Trieste",
      "Padova",
      "Taranto",
      "Brescia",
      "Modena",
      "Reggio di calabria",
      "Prato",
      "Cagliari",
      "Parma",
      "Perugia",
      "Livorno",
      "Foggia",
      "Salerno",
      "Reggio nell'emilia",
      "Ravenna",
      "Ferrara",
      "Rimini",
      "Siracusa",
      "Monza",
      "Pescara",
      "Sassari",
      "Latina",
      "Bergamo",
      "Forli",
      "Vicenza",
      "Terni",
      "Trento",
      "Novara",
      "Ancona",
      "Piacenza",
      "Udine",
      "Bolzano",
      "Arezzo",
      "Catanzaro",
      "Andria",
      "Giugliano in campania",
      "Brindisi",
      "Pesaro",
      "Cesena",
      "Torre del greco",
      "Barletta",
      "La spezia",
      "Pisa",
      "Pistoia",
      "Varese",
      "Alessandria",
      "Lecce",
      "Treviso",
      "Lucca",
      "Marsala",
      "Pozzuoli",
      "Busto arsizio",
      "Casoria",
      "Sesto san giovanni",
      "Caserta",
      "Gela",
      "Cinisello balsamo",
      "Guidonia",
      "Pavia",
      "Asti",
      "Cremona",
      "Cosenza",
      "Grosseto",
      "Ragusa",
      "Quartu sant'elena",
      "Lamezia terme",
      "Trapani",
      "Potenza",
      "Massa",
      "Castellammare di stabia",
      "Carrara",
      "L'aquila",
      "Imola",
      "Altamura",
      "Caltanissetta",
      "Carpi",
      "Benevento",
      "Molfetta",
      "Viterbo",
      "Aprilia",
      "Afragola",
      "Portici",
      "Savona",
      "Viareggio",
      "Fano",
      "Cerignola",
      "Manfredonia",
      "Aversa",
      "Vigevano",
      "Bitonto",
      "Vittoria",
      "Matera",
      "San severo",
      "Legnano",
      "Ercolano",
      "Agrigento",
      "Faenza",
      "Modica",
      "Moncalieri",
      "San giorgio a cremano",
      "Chioggia",
      "Avellino",
      "Cava de' tirreni",
      "Rho",
      "Velletri",
      "Cuneo",
      "Trani",
      "Chieti",
      "Bagheria",
      "Bisceglie",
      "Foligno",
      "Ascoli piceno",
      "Civitavecchia",
      "Crotone",
      "Scandicci",
      "Battipaglia",
      "Acireale",
      "Rivoli",
      "San remo",
      "Mazara del vallo",
      "Teramo",
      "Siena",
      "Tivoli",
      "Marano di napoli",
      "Rovigo",
      "Torre annunziata",
      "Cologno monzese",
      "Monopoli",
      "Pordenone",
      "Frosinone",
      "Nocera inferiore",
      "Mantova",
      "Bollate",
      "Gallarate",
      "Nichelino",
      "Campobasso",
      "Fiumicino",
      "Lecco",
      "Martina franca",
      "Sesto fiorentino",
      "Paderno dugnano",
      "Biella",
      "Paterno",
      "Settimo torinese",
      "Vercelli",
      "Scafati",
      "Pomezia",
      "Casalnuovo di napoli",
      "Misterbianco",
      "Acerra",
      "Empoli",
      "Rieti",
      "Corato",
      "San benedetto del tronto",
      "Alcamo",
      "Barcellona",
      "Olbia",
      "Gravina in puglia",
      "Senigallia",
      "Sciacca",
      "Bassano del grappa",
      "Macerata",
      "Capannori",
      "Alghero",
      "Lodi",
      "Montesilvano",
      "Marcianise",
      "Pomigliano d'arco",
      "Jesi",
      "Seregno",
      "Citta di castello",
      "Imperia",
      "Voghera",
      "Anzio",
      "Cascina",
      "Civitanova marche",
      "Nettuno",
      "Ciampino",
      "Fasano",
      "Formia",
      "Schio",
      "Arzano",
      "Caltagirone",
      "Avezzano",
      "Sassuolo",
      "Saronno",
      "Campi bisenzio",
      "Nuoro",
      "Terracina",
      "Grugliasco",
      "Maddaloni",
      "Lanciano",
      "Rozzano",
      "Spoleto",
      "Francavilla fontana",
      "Monterotondo",
      "Vasto",
      "Corigliano calabro",
      "San dona di piave",
      "Caivano",
      "Cantu",
      "Mira",
      "Eboli",
      "Licata",
      "Modugno",
      "Conegliano",
      "Belluno",
      "Turin",
      "Florence",
      "Palermo",
      "Bologna",
      "Bari",
      "Messina",
      "Pescara",
      "Trieste",
      "Reggio di Calabria",
      "Modena",
      "Naples",
      "Livorno",
      "Treviso",
      "Ravenna",
      "Lecce",
      "Udine",
      "Sassari",
      "Asti",
      "Genoa",
      "L'Aquila",
      "Marsala",
      "Crotone",
      "Salerno",
      "Catania"
    ]
  },
  {
    value: "India",
    label: "🇮🇳 India",
    children: [
      "Delhi",
      "Bangalore",
      "Chennai",
      "Kanpur",
      "Lucknow",
      "Indore",
      "Mumbai",
      "Agra",
      "Nasik",
      "Coimbatore",
      "Ghaziabad",
      "Aurangabad",
      "Srinagar",
      "Vijayawada",
      "Kolkata",
      "Chandigarh",
      "Mysore",
      "Thiruvananthapuram",
      "Bhubaneshwar",
      "Bareilly",
      "Aligarh",
      "Raipur",
      "Moradabad",
      "Bhiwandi",
      "Kolhapur",
      "Dehra Dun",
      "Jammu",
      "Jhansi",
      "Belgaum",
      "Mangalore",
      "Tiruppur",
      "Hyderabad",
      "Shimoga",
      "Bikaner",
      "Gulbarga",
      "Davangere",
      "Akola",
      "Chandrapur",
      "Ahmedabad",
      "Udaipur",
      "Dhule",
      "Bellary",
      "Latur",
      "Tumkur",
      "Nizamabad",
      "Surat",
      "Parbhani",
      "Sikar",
      "Haora",
      "Sagar",
      "Jaipur",
      "Bidar",
      "Imphal",
      "Hospet",
      "Sambalpur",
      "Nagpur",
      "Nagercoil",
      "Pathankot",
      "Mandya",
      "Patna",
      "Bhusawal",
      "Nandyal",
      "Chirala",
      "Bhopal",
      "Ludhiana",
      "Vadodara",
      "Kalyan",
      "Dibrugarh",
      "Faridabad",
      "Asansol",
      "Meerut",
      "Vishakhapatnam",
      "Varanasi",
      "Rajkot",
      "Jabalpur",
      "Amritsar",
      "Allahabad",
      "Madurai",
      "Bhilai",
      "Kochi",
      "Warangal",
      "Valparai",
      "Sholapur",
      "Jodhpur",
      "Jamshedpur",
      "Ranchi",
      "Gwalior",
      "Tiruchirappalli",
      "Hubli",
      "Jullundur",
      "Dhanbad",
      "Kozhikode",
      "Gorakhpur",
      "Amravati",
      "Sopur",
      "Sangli",
      "Nanded",
      "Cuttack",
      "Malegaon",
      "Raurkela",
      "Ajmer",
      "Nellore",
      "Guntur",
      "Siliguri",
      "Bhavnagar",
      "Guwahati",
      "Tirunelveli",
      "Ujjain",
      "Saharanpur",
      "Bhatpara",
      "Bilaspur",
      "Tuticorin",
      "Hisar"
    ]
  },
  {
    value: "Jamaica",
    label: "🇯🇲 Jamaica",
    children: [
      "Kingston",
      "Spanish Town",
      "Montego Bay",
      "May Pen"
    ]
  },
  {
    value: "Japan",
    label: "🇯🇵 Japan",
    children: [
      "Tokyo",
      "Osaka",
      "Nagoya",
      "Fukuoka",
      "Hiroshima",
      "Kawasaki",
      "Kitakyushu",
      "Hamamatsu",
      "Kumamoto",
      "Shizuoka",
      "Utsunomiya",
      "Niigata",
      "Kagoshima",
      "Nagano",
      "Otsu",
      "Nagasaki",
      "Oita",
      "Gifu",
      "Yokohama",
      "Tokushima",
      "Asahikawa",
      "Takamatsu",
      "Miyazaki",
      "Akita",
      "Morioka",
      "Aomori",
      "Fukushima",
      "Yamagata",
      "Fukui",
      "Shimonoseki",
      "Sasebo",
      "Sapporo",
      "Matsumoto",
      "Kure",
      "Hirosaki",
      "Sendai",
      "Kyoto",
      "Tomakomai",
      "Kobe",
      "Tottori",
      "Otaru",
      "Muroran",
      "Kitami",
      "Tsuruoka",
      "Sakata",
      "Okayama",
      "Kanoya",
      "Maizuru",
      "Hachioji",
      "Matsuyama",
      "Kanazawa"
    ]
  },
  {
    value: "Kuwait",
    label: "🇰🇼 Kuwait",
    children: [
      "Kuwait"
    ]
  },
  {
    value: "Libya",
    label: "🇱🇾 Libya",
    children: [
      "Az Zawiyah",
      "Tubruq",
      "Ajdabiya",
      "Al Marj",
      "Tripoli",
      "Gharyan",
      "Surt",
      "Banghazi",
      "Nalut",
      "Marzuq",
      "Birak"
    ]
  },
  {
    value: "Lithuania",
    label: "🇱🇹 Lithuania",
    children: [
      "Vilnius",
      "Kaunas",
      "Siauliai"
    ]
  },
  {
    value: "Luxembourg",
    label: "🇱🇺 Luxembourg",
    children: []
  },
  {
    value: "Macedonia",
    label: "🇲🇰 Macedonia",
    children: [
      "Skopje"
    ]
  },
  {
    value: "Mexico",
    label: "🇲🇽 Mexico",
    children: [
      "Guadalajara",
      "Puebla",
      "Mexico City",
      "Tijuana",
      "Toluca",
      "Nezahualcoyotl",
      "Merida",
      "Queretaro",
      "Aguascalientes",
      "Chihuahua",
      "Mexicali",
      "Culiacan",
      "Saltillo",
      "Morelia",
      "Cuernavaca",
      "Tampico",
      "Cancun",
      "Durango",
      "Matamoros",
      "Villahermosa",
      "Mazatlan",
      "Gomez Palacio",
      "Tepic",
      "Tlaxcala",
      "Ciudad Victoria",
      "Nuevo Laredo",
      "Uruapan",
      "Monterrey",
      "Tehuacan",
      "Orizaba",
      "Poza Rica de Hidalgo",
      "Campeche",
      "Zacatecas",
      "Minatitlan",
      "Chilpancingo",
      "Zamora",
      "Ciudad Madero",
      "San Cristobal de Las Casas",
      "Ciudad del Carmen",
      "Chetumal",
      "Ciudad Juarez",
      "Teziutlan",
      "Iguala",
      "Fresnillo",
      "Apatzingan",
      "Atlixco",
      "Manzanillo",
      "Cuauhtemoc",
      "Guaymas",
      "San Luis Potosi",
      "Torreon",
      "Guasave",
      "Ciudad Mante",
      "Salina Cruz",
      "Agua Prieta",
      "Acapulco",
      "Matehuala",
      "Juchitan",
      "Guamuchil",
      "Ciudad Hidalgo",
      "Rio Verde",
      "Escuintla",
      "Veracruz",
      "Hermosillo",
      "Caborca",
      "Linares",
      "Tuxtla Gutierrez",
      "Tamazunchale",
      "Mazatlan",
      "Reynosa",
      "Xalapa",
      "Huajuapan de Leon",
      "Tizimin",
      "Tehuantepec"
    ]
  },
  {
    value: "Morocco",
    label: "🇲🇦 Morocco",
    children: [
      "Casablanca",
      "Rabat",
      "Fez",
      "Agadir",
      "Meknes",
      "Safi",
      "Ksar El Kebir",
      "Laayoune",
      "El Jadida",
      "Marrakesh",
      "Tangier",
      "Tiznit",
      "Kenitra",
      "Oujda"
    ]
  },
  {
    value: "Netherlands",
    label: "🇳🇱 Netherlands",
    children: [
      "The Hague",
      "Rotterdam",
      "Eindhoven",
      "Haarlem",
      "Amsterdam",
      "Utrecht"
    ]
  },
  {
    value: "New Zealand",
    label: "🇳🇿 New Zealand",
    children: [
      "Manukau",
      "Christchurch",
      "Takapuna",
      "Dunedin",
      "Auckland",
      "Palmerston North",
      "Whangarei",
      "Rotorua",
      "New Plymouth"
    ]
  },
  {
    value: "Nicaragua",
    label: "🇳🇮 Nicaragua",
    children: [
      "Matagalpa",
      "Esteli",
      "Managua"
    ]
  },
  {
    value: "Norway",
    label: "🇳🇴 Norway",
    children: [
      "Bergen",
      "Drammen",
      "Oslo",
      "Tromso"
    ]
  },
  {
    value: "Oman",
    label: "🇴🇲 Oman",
    children: [
      "Salalah",
      "Suhar",
      "Nizwa",
      "Muscat"
    ]
  },
  {
    value: "Panama",
    label: "🇵🇦 Panama",
    children: [
      "Panama City"
    ]
  },
  {
    value: "Paraguay",
    label: "🇵🇾 Paraguay",
    children: [
      "Asuncion",
      "Encarnacion",
      "Coronel Oviedo",
      "Concepcion"
    ]
  },
  {
    value: "Peru",
    label: "🇵🇪 Peru",
    children: [
      "Callao",
      "Lima",
      "Chiclayo",
      "Iquitos",
      "Tacna",
      "Ayacucho",
      "Cajamarca",
      "Sullana",
      "Arequipa",
      "Huaraz",
      "Talara",
      "Huacho",
      "Trujillo",
      "Paita",
      "Chosica",
      "Ferrenafe"
    ]
  },
  {
    value: "Philippines",
    label: "🇵🇭 Philippines",
    children: [
      "Manila",
      "Cagayan de Oro",
      "Zamboanga",
      "Quezon City",
      "San Pablo",
      "Laoag",
      "Davao",
      "Ormac",
      "Roxas",
      "Cebu",
      "General Santos",
      "Bacolod",
      "Iligan",
      "Pasay City"
    ]
  },
  {
    value: "Poland",
    label: "🇵🇱 Poland",
    children: [
      "Warsaw",
      "Poznan",
      "Bytom",
      "Szczecin",
      "Gliwice",
      "Bialystok",
      "Kielce",
      "Olsztyn",
      "Katowice",
      "Zielona Gora",
      "Grudziadz",
      "Lodz",
      "Krakow",
      "Wroclaw",
      "Gdansk"
    ]
  },
  {
    value: "Portugal",
    label: "🇵🇹 Portugal",
    children: [
      "Porto",
      "Lisbon",
      "Coimbra",
      "Braga",
      "Portimao"
    ]
  },
  {
    value: "Romania",
    label: "🇷🇴 Romania",
    children: [
      "Galati",
      "Craiova",
      "Cluj-Napoca",
      "Constanta",
      "Ploiesti",
      "Bucharest",
      "Sibiu",
      "Suceava",
      "Focsani",
      "Tulcea",
      "Calarasi",
      "Miercurea-Cuic"
    ]
  },
  {
    value: "Russia",
    label: "🇷🇺 Russia",
    children: [
      "Novosibirsk",
      "Omsk",
      "Moscow",
      "Saratov",
      "Volgograd",
      "Krasnodar",
      "Ulyanovsk",
      "Voronezh",
      "Khabarovsk",
      "Orenburg",
      "Novokuznetsk",
      "Astrakhan",
      "Tula",
      "Kemerovo",
      "Cheboksary",
      "Kaliningrad",
      "St. Petersburg",
      "Nizhny Tagil",
      "Surgut",
      "Stavropol",
      "Kurgan",
      "Belgorod",
      "Kaluga",
      "Smolensk",
      "Magnitogorsk",
      "Volzhskiy",
      "Saransk",
      "Tambov",
      "Archangel",
      "Chita",
      "Cherepovets",
      "Kostroma",
      "Podolsk",
      "Petrozavodsk",
      "Dzerzhinsk",
      "Syktyvkar",
      "Groznyy",
      "Biysk",
      "Shakhty",
      "Rybinsk",
      "Armavir",
      "Pskov",
      "Petropavlovsk Kamchatskiy",
      "Severodvinsk",
      "Kolpino",
      "Kamensk Uralskiy",
      "Balakovo",
      "Novocherkassk",
      "Norilsk",
      "Nakhodka",
      "Miass",
      "Ussuriysk",
      "Nevinnomyssk",
      "Kolomna",
      "Orekhovo-Zuevo",
      "Yekaterinburg",
      "Nizhny Novgorod",
      "Volgodonsk",
      "Dimitrovgrad",
      "Yelets",
      "Achinsk",
      "Leninsk Kuznetsky",
      "Sergiyev Posad",
      "Bataysk",
      "Kyzyl",
      "Rostov",
      "Chelyabinsk",
      "Kazan",
      "Samara",
      "Ukhta",
      "Kansk",
      "Sarapul",
      "Magadan",
      "Novotroitsk",
      "Gatchina",
      "Chapayevsk",
      "Tobolsk",
      "Yegoryevsk",
      "Pyatigorsk",
      "Novoshakhtinsk",
      "Tuapse",
      "Yeysk",
      "Birobidzhan",
      "Novomoskovsk",
      "Kandalaksha",
      "Yurga",
      "Kirovo-Chepetsk",
      "Vorkuta",
      "Kamensk Shakhtinskiy",
      "Shadrinsk",
      "Tolyatti",
      "Apatity",
      "Tikhoretsk",
      "Svobodnyy",
      "Kaspiysk",
      "Krasnoyarsk",
      "Izhevsk",
      "Iskitim",
      "Kungur",
      "Shuya",
      "Kotlas",
      "Georgievsk",
      "Vladivostok",
      "Gorno Altaysk",
      "Mikhaylovka",
      "Irkutsk",
      "Yaroslavl",
      "Barnaul",
      "Gelendzhik",
      "Sayanogorsk",
      "Makhachkala",
      "Livny",
      "Chistopol",
      "Cheremkhovo",
      "Zelenodolsk",
      "Ryazan",
      "Lipetsk",
      "Penza",
      "Vyazma",
      "Kanash",
      "Kumertau",
      "Belaya Kalitva",
      "Megion",
      "Novy Urengoy",
      "Tomsk",
      "Monchegorsk",
      "Morshansk",
      "Nyagan",
      "Naberezhnyye Chelny",
      "Tyumen",
      "Amursk",
      "Kirov",
      "Volkhov",
      "Alatyr",
      "Alapayevsk",
      "Mozdok",
      "Apsheronsk",
      "Novozybkov",
      "Usinsk",
      "Bryansk",
      "Chernyakhovsk",
      "Ivanovo",
      "Mariinsk",
      "Shebekino",
      "Nizhneudinsk",
      "Partizansk",
      "Slobodskoy",
      "Krasnoufimsk",
      "Sovetsk",
      "Frolovo"
    ]
  },
  {
    value: "Qatar",
    label: "🇶🇦 Qatar",
    children: [
      "Doha"
    ]
  },
  {
    value: "Saudi Arabia",
    label: "🇸🇦 Saudi Arabia",
    children: [
      "Riyadh",
      "Al Hufuf",
      "Tabuk",
      "Buraydah",
      "Jeddah",
      "Yanbu al Bahr",
      "Al-Qatif",
      "Abha",
      "Ad Damman",
      "Makkah",
      "Medina",
      "Qal at Bishah",
      "Al Hillah",
      "At Taif"
    ]
  },
  {
    value: "Serbia",
    label: "🇷🇸 Serbia",
    children: [
      "Novi Sad",
      "Belgrade"
    ]
  },
  {
    value: "Singapore",
    label: "🇸🇬  Singapore",
    children: [
      "Singapore"
    ]
  },
  {
    value: "Slovakia",
    label: "🇸🇰 Slovakia",
    children: [
      "Kosice",
      "Presov"
    ]
  },
  {
    value: "Slovenia",
    label: "🇸🇮 Slovenia",
    children: []
  },
  {
    value: "South Africa",
    label: "🇿🇦 South Africa",
    children: [
      "Johannesburg",
      "Vereeniging",
      "Bloemfontein",
      "Cape Town",
      "Welkom",
      "Durban",
      "Springs",
      "Benoni",
      "Klerksdorp",
      "Paarl",
      "Thohoyandou",
      "Kimberley",
      "Pretoria",
      "Vryheid",
      "Umtata",
      "Potchefstroom",
      "Queenstown",
      "Bethal",
      "Kroonstad",
      "Port Elizabeth",
      "Grahamstown",
      "Pietermaritzburg",
      "Tzaneen"
    ]
  },
  {
    value: "South Korea",
    label: "🇰🇷 South Korea",
    children: [
      "Ulsan",
      "Songnam",
      "Seoul",
      "Cheongju",
      "Ansan",
      "Jeonju",
      "Busan",
      "Incheon",
      "Daegu",
      "Chuncheon",
      "Gyeongju",
      "Daejeon",
      "Gwangju",
      "Andong",
      "Masan",
      "Suwon",
      "Goyang",
      "Puch'on",
      "Pohang"
    ]
  },
  {
    value: "Spain",
    label: "🇪🇸 Spain",
    children: [
      "Barcelona",
      "Madrid",
      "Bilbao",
      "Zaragoza",
      "Malaga",
      "Murcia",
      "Vigo",
      "La Coruna",
      "Valladolid",
      "Santa Cruz de Tenerife",
      "Pamplona",
      "Oviedo",
      "Vitoria",
      "Santander",
      "Cartagena",
      "Salamanca",
      "Cadiz",
      "Marbella",
      "Almeria",
      "Logrono",
      "Badajoz",
      "Tarragona",
      "Algeciras",
      "Seville",
      "Valencia",
      "Lorca",
      "Toledo",
      "Guadalajara",
      "Linares",
      "Arrecife"
    ]
  },
  {
    value: "Sweden",
    label: "🇸🇪 Sweden",
    children: [
      "Stockholm",
      "Goteborg",
      "Malmo",
      "Uppsala",
      "Vasteraas",
      "Linkoping",
      "Helsingborg",
      "Gavle",
      "Karlstad",
      "Boras",
      "Vaxjo",
      "Lulea",
      "Trollhattan"
    ]
  },
  {
    value: "Taiwan",
    label: "🇹🇼 Taiwan",
    children: [
      "Taipei",
      "Kaohsiung",
      "Hsinchu",
      "Keelung",
      "Pingtung",
      "New Taipei",
      "Taichung",
      "Tainan",
      "Yilan",
      "Zhongli",
      "Changhua",
      "Taoyuan"
    ]
  },
  {
    value: "Thailand",
    label: "🇹🇭 Thailand",
    children: [
      "Bangkok",
      "Chiang Mai",
      "Nakhon Ratchasima",
      "Chon Buri",
      "Khon Kaen",
      "Nakhon Si Thammarat",
      "Ayutthaya",
      "Phuket",
      "Trang",
      "Chiang Rai",
      "Pattani",
      "Chanthaburi",
      "Si Racha",
      "Chumphon",
      "Sakhon Nakhon",
      "Uttaradit",
      "Narathiwat",
      "Prachin Buri",
      "Surin",
      "Lop Buri"
    ]
  },
  {
    value: "Turkey",
    label: "🇹🇷 Turkey",
    children: [
      "Ankara",
      "Bursa",
      "Istanbul",
      "Antalya",
      "Diyarbakir",
      "Samsun",
      "Kayseri",
      "Trabzon",
      "Eskisehir",
      "Malatya",
      "Sanliurfa",
      "Kocaeli",
      "Kahramanmaras",
      "Batman",
      "Balikesir",
      "Sivas",
      "Izmir",
      "Kirikkale",
      "Rize",
      "Aydin",
      "Kutahya",
      "Usak",
      "Ordu",
      "Zonguldak",
      "Adana",
      "Nusaybin",
      "Karabuk",
      "Karaman",
      "Gaziantep",
      "Amasya",
      "Polatli",
      "Konya",
      "Tatvan",
      "Luleburgaz",
      "Mardin",
      "Icel",
      "Tarsus",
      "Mugla"
    ]
  },
  {
    value: "Tunisia",
    label: "🇹🇳 Tunisia",
    children: [
      "Tunis",
      "Sousse",
      "Bizerte",
      "Zarzis",
      "Gafsa",
      "Qasserine",
      "El Kef"
    ]
  },
  {
    value: "Ukraine",
    label: "🇺🇦 Ukraine",
    children: [
      "Kharkiv",
      "Dnipropetrovsk",
      "Donetsk",
      "Odessa",
      "Lvov",
      "Zaporizhzhya",
      "Kryvyy Rih",
      "Mykolayiv",
      "Sevastapol",
      "Horlivka",
      "Makiyivka",
      "Simferopol",
      "Poltava",
      "Chernihiv",
      "Cherkasy",
      "Kherson",
      "Chernivtsi",
      "Kirovohrad",
      "Ivano-Frankivsk",
      "Kiev",
      "Kremenchuk",
      "Bila Tserkva",
      "Kramatorsk",
      "Lysychansk",
      "Drohobych",
      "Konotop",
      "Nizhyn",
      "Shostka",
      "Izmayil",
      "Brovary",
      "Yalta",
      "Kovel",
      "Mariupol",
      "Luhansk"
    ]
  },
  {
    value: "United Kingdom",
    label: "🇬🇧 United Kingdom",
    children: [
      "London",
      "Birmingham",
      "Manchester",
      "Leeds",
      "Liverpool",
      "Bristol",
      "Edinburgh",
      "Bradford",
      "Brighton",
      "Sunderland",
      "Kingston upon Hull",
      "Bournemouth",
      "Middlesbrough",
      "Luton",
      "Blackpool",
      "Dundee",
      "Sheffield",
      "Glasgow",
      "Cardiff",
      "Nottingham",
      "Newcastle",
      "Inverness"
    ]
  },
  {
    value: "United Arab Emirates",
    label: "🇿🇦 United Arab Emirates",
    children: [
      "Dubai",
      "Sharjah",
      "Al Ayn",
      "Abu Dhabi"
    ]
  },
  {
    value: "Uruguay",
    label: "🇺🇾 Uruguay",
    children: [
      "Rivera",
      "Salto",
      "Paysandu",
      "Montevideo",
      "Tacuarembo",
      "Maldonado"
    ]
  }
]

export default data
