import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  pricingRequest: ['query'],
  pricingSuccess: ['priceList'],

  searchRequest: ['query'],
  searchSuccess: ['searchData'],

  listAddRequest: ['query'],
  listAddSuccess: ['result'],

  listEditRequest: ['query'],
  listEditSuccess: ['result'],

  listDeleteRequest: ['query'],
  listDeleteSuccess: ['result'],

  checkoutRequest: ['query'],
  checkoutSuccess: ['checkoutResult'],

  exportRequest: ['query'],
  exportSuccess: ['exportResult'],

  getInvoiceRequest: ['query'],
  getInvoiceSuccess: ['getInvoiceResult'],

  mainReset: null,

  resultsReset: null,
  fetchingReset: null
})

export const MainTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  priceList: null,
  searchData: null,

  result: null,
  fetching: false,
  saveFetching: false,
  deleteFetching: false,

  checkoutResult: null,
  exportResult: null,
  getInvoiceResult: null,

  page: 1,
  page_size: 10,
  filters: null

})

/* ------------- Selectors ------------- */
export const MainSelectors = {
  getState: (state) => state.main
}

/* ------------- Reducers ------------- */

export const pricingRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const pricingSuccess = (state, { priceList }) => {
  return state.merge({ fetching: false, priceList })
}

export const searchRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const searchSuccess = (state, { searchData }) => {
  return state.merge({ fetching: false, searchData })
}

export const listAddRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const listAddSuccess = (state, { result }) => {
  return state.merge({ fetching: false, result })
}

export const listEditRequest = (state, { query }) => {
  return state.merge({ saveFetching: true })
}

export const listEditSuccess = (state, { result }) => {
  return state.merge({ saveFetching: false, result })
}

export const listDeleteRequest = (state, { query }) => {
  return state.merge({ deleteFetching: true })
}

export const listDeleteSuccess = (state, { result }) => {
  return state.merge({ deleteFetching: false, result })
}

export const checkoutRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const checkoutSuccess = (state, { checkoutResult }) => {
  return state.merge({ fetching: false, checkoutResult })
}

export const exportRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const exportSuccess = (state, { exportResult }) => {
  return state.merge({ fetching: false, exportResult })
}

export const getInvoiceRequest = (state, { query }) => {
  return state.merge({ fetching: true })
}

export const getInvoiceSuccess = (state, { getInvoiceResult }) => {
  return state.merge({ fetching: false, getInvoiceResult })
}

export const mainReset = (state) => {
  return INITIAL_STATE
}

export const resultsReset = (state) => {
  return state.merge({
    result: null,
    checkoutResult: null,
    exportResult: null,
    getInvoiceResult: null,
  })
}

export const fetchingReset = (state) => {
  return state.merge({
    fetching: false,
    saveFetching: false,
    deleteFetching: false,
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRICING_REQUEST]: pricingRequest,
  [Types.PRICING_SUCCESS]: pricingSuccess,

  [Types.SEARCH_REQUEST]: searchRequest,
  [Types.SEARCH_SUCCESS]: searchSuccess,

  [Types.LIST_ADD_REQUEST]: listAddRequest,
  [Types.LIST_ADD_SUCCESS]: listAddSuccess,

  [Types.LIST_EDIT_REQUEST]: listEditRequest,
  [Types.LIST_EDIT_SUCCESS]: listEditSuccess,

  [Types.LIST_DELETE_REQUEST]: listDeleteRequest,
  [Types.LIST_DELETE_SUCCESS]: listDeleteSuccess,

  [Types.CHECKOUT_REQUEST]: checkoutRequest,
  [Types.CHECKOUT_SUCCESS]: checkoutSuccess,

  [Types.EXPORT_REQUEST]: exportRequest,
  [Types.EXPORT_SUCCESS]: exportSuccess,

  [Types.GET_INVOICE_REQUEST]: getInvoiceRequest,
  [Types.GET_INVOICE_SUCCESS]: getInvoiceSuccess,

  [Types.MAIN_RESET]: mainReset,

  [Types.RESULTS_RESET]: resultsReset,

  [Types.FETCHING_RESET]: fetchingReset,
})
